import { CAPTCHA_CODE } from '@/utils/cons';

const getInitialState = () => ({
  mfaCode: null,
  cashedMfaCode: '',
  userEmail: '',
  loginError: '',
  isForcedLogin: false, // re-enter base authorization
  captchaIP: '',
  forcedMfaCode: '',
});

export default {
  namespaced: true,
  state: {
    ...getInitialState(),
    isLoggedIn: false,
  },
  mutations: {
    setMfaCode(state, mfaCode) {
      // ignore new MFA codes if forced is present
      if (state.forcedMfaCode) {
        return;
      }
      if (!mfaCode) {
        state.mfaCode = null;
        state.cashedMfaCode = '';
      } else {
        const MFA_AS_NUMBER = +mfaCode;
        state.cashedMfaCode = MFA_AS_NUMBER === CAPTCHA_CODE ? state.mfaCode : '';
        state.mfaCode = MFA_AS_NUMBER;
      }
      // on change mfa code old errors becomes irrelevant
      state.loginError = '';
    },
    setUserEmail(state, email) {
      state.userEmail = email;
    },
    setLoginError(state, errorKey) {
      state.loginError = errorKey;
    },
    setIsLoggedIn(state, status) {
      state.isLoggedIn = status;
    },
    setCaptchaIP(state, ip) {
      state.captchaIP = ip;
    },
    setForcedMfaCode(state, code) {
      state.forcedMfaCode = Boolean(code);
      state.mfaCode = code;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
    setIsForcedLogin(state, status) {
      state.isForcedLogin = status;
    },
  },
};
