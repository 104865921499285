import random from '@/libs/openpgp/random.js';

if (!window.crypto || !window.crypto.getRandomValues) {
  if (window.msCrypto) {
    window.crypto = window.msCrypto;
  } else {
    window.crypto = window.crypto || {};
    window.crypto.getRandomValues = function(array) {
      if (array.buffer instanceof ArrayBuffer) {
        var bytes = array.BYTES_PER_ELEMENT;
        var max = Math.pow(256, bytes) - 1;
        for (var i = 0, l = array.length; i < l; i++) {
          array[i] = Math.round(random() * max);
        }
        return array;
      } else {
        throw new TypeError('First argument is not an ArrayBufferView');
      }
    };
  }
}
