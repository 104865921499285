import Vue from 'vue';

const getInitialState = () => ({
  pfList: [],
  pfUserPermissions: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parse: () => (data) => {
      return {
        id: data.project_id,
        name: data.name,
        owner: data.owner.email,
        owns: false,
        notify: false,
        operations: null,
        permissions: null,
        isCheckedState: false,
        isDisabledState: false,
        isActive: true,
      };
    },
    userPredefinedPermissions: () => {
      return [
        {
          id: 'list_only',
          text: Vue.prototype.$gettext('List only'),
          permissions: { list: true },
        },
        {
          id: 'list_and_read',
          text: Vue.prototype.$gettext('List and read'),
          permissions: {
            list: true,
            download: true,
            comments: true,
            preview: true,
          },
        },
        {
          id: 'read_and_write',
          text: Vue.prototype.$gettext('Read and write'),
          permissions: {
            list: true,
            upload: true,
            download: true,
            mkdir: true,
            delete: true,
            rename: true,
            comments: true,
            preview: true,
          },
        },
        {
          id: 'full_and_manage',
          text: Vue.prototype.$gettext('Full and manage'),
          permissions: {
            list: true,
            upload: true,
            download: true,
            mkdir: true,
            delete: true,
            rename: true,
            share: true,
            comments: true,
            preview: true,
            manage_pf: true,
          },
        },
        {
          id: 'none',
          text: Vue.prototype.$gettext('None'),
          permissions: {
            list: false,
            upload: false,
            download: false,
            mkdir: false,
            delete: false,
            rename: false,
            share: false,
            comments: false,
            preview: false,
            manage_pf: false,
          },
        },
      ];
    },
    userGranularPermissions: () => {
      return [
        {
          id: 'list',
          text: Vue.prototype.$gettext('List'),
          permissions: {
            list: true,
          },
        },
        {
          id: 'upload',
          text: Vue.prototype.$gettext('Upload'),
          permissions: { upload: true },
        },
        {
          id: 'download',
          text: Vue.prototype.$gettext('Download'),
          permissions: { download: true },
        },
        {
          id: 'mkdir',
          text: Vue.prototype.$gettext('Create folder'),
          permissions: { mkdir: true },
        },
        {
          id: 'delete',
          text: Vue.prototype.$gettext('Delete'),
          permissions: { delete: true },
        },
        {
          id: 'rename',
          text: Vue.prototype.$gettext('Rename'),
          permissions: { rename: true },
        },
        {
          id: 'share',
          text: Vue.prototype.$gettext('Share files'),
          permissions: { share: true },
        },
        {
          id: 'manage_pf',
          text: Vue.prototype.$gettext('Manage users'),
          permissions: { manage_pf: true },
        },
        {
          id: 'comments',
          text: Vue.prototype.$gettext('Write comments'),
          permissions: { comments: true },
        },
        {
          id: 'preview',
          text: Vue.prototype.$gettext('Preview file'),
          permissions: { preview: true },
        },
      ];
    },
    isEqualPermissions: () => (permission1, permission2) => {
      if (!permission1 || !permission2) {
        return false;
      }
      const diff1 = Object.keys(permission1).find((permKey) => permission1[permKey] !== Boolean(permission2[permKey]));
      const diff2 = Object.keys(permission2).find((permKey) => permission2[permKey] !== Boolean(permission1[permKey]));
      return !(diff1 || diff2);
    },
    getLabelTextByPermissions: (state, getters) => (permissions) => {
      if (!permissions) {
        return;
      }
      const predefinedPermission = getters.userPredefinedPermissions.find((permission) => {
        return getters.isEqualPermissions(permissions, permission.permissions);
      });
      const noneLabel = Vue.prototype.$gettext('None');
      if (predefinedPermission) {
        return predefinedPermission.text;
      } else {
        const granularPermissions = getters.userGranularPermissions.filter(
          (permission) => permissions[Object.keys(permission.permissions)[0]]
        );
        return granularPermissions?.map((permission) => permission.text)?.join(', ') || noneLabel;
      }
    },
    getPermissionByOperations: (state, getters, rootState, rootGetters) => (operations) => {
      return rootGetters['operations/parse']('file', operations);
    },
    getOperationsByPermissions: (state, getters, rootState, rootGetters) => (permissions) => {
      return rootGetters['operations/getAttributes']('file', permissions);
    },
  },
  mutations: {
    setList(state, list) {
      state.pfList = list;
    },
    setUsersPermissions(state, list) {
      state.pfUserPermissions = list;
    },
    setPFPermissions(state, permission) {
      const pfIndex = state.pfList.findIndex((projectFolder) => projectFolder.id === permission.project_id);
      Vue.set(state.pfList, pfIndex, {
        ...state.pfList[pfIndex],
        owns: permission.owner,
        notify: permission.notify,
        operations: permission.operations,
        permissions: permission.permissions,
        isCheckedState: true,
        isDisabledState: permission.owner,
        isActive: true,
      });
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    getList({ commit, getters }, options = {}) {
      API.getProjectFolderList(({ status }, resp) => {
        if (status === 200) {
          const parsedList = resp.map((projectFolder) => getters.parse(projectFolder));
          commit('setList', parsedList);
          options.success?.(resp);
        } else {
          options.error?.(status, resp);
        }
      });
    },
    getUsersPF({ commit, getters }, options = {}) {
      API.getUsersProjectFolder(
        {
          ids: options.ids,
        },
        ({ status }, resp) => {
          if (status === 200) {
            if (!resp.length && options.isCopyPermissions) {
              Vue.prototype.$notify({
                type: 'warning',
                text: Vue.prototype.$gettext('This user is not subscribed to any Project Folders.'),
              });
              options.success?.(resp);
              return;
            }
            commit('setUsersPermissions', resp);
            resp.forEach((perm) => {
              //If called from "Copy permissions from another user" we need skip owner property
              if (options.isCopyPermissions) {
                perm.owner = false;
              }
              perm.permissions = !perm.owner && getters.getPermissionByOperations(perm.operations);
              commit('setPFPermissions', perm);
            });
            options.success?.(resp);
          } else {
            options.error?.(status, resp);
          }
        }
      );
    },
  },
};
