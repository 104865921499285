<template>
  <div class="qx-icon icon-spinner rotate-animation" />
</template>

<script>
export default {
  name: 'QxSpinnerInline',
};
</script>

<style lang="scss" scoped>
.rotate-animation {
  width: 24px;
  height: 24px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  &:hover {
    color: $base-grey !important;
    cursor: default !important;
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
