import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import EventBus from '@/elements/eventBus.js';

Vue.use(VueRouter);

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const permissionGuard = (permissions) => (to, from, next) => {
  if (Array.isArray(permissions)) {
    if (permissions.some((permission) => store.getters['profile/checkOperations'](permission))) {
      next();
    } else {
      next({ name: 'main' });
    }
    return;
  }
  if (store.getters['profile/checkOperations'](permissions)) {
    next();
  } else {
    next({ name: 'main' });
  }
};

const router = new VueRouter({
  linkActiveClass: 'current-btn',
  mode: 'history',
  routes: [
    {
      path: '/',
      alias: '*',
      name: 'main',
      redirect() {
        const modules = store.getters.getModules;
        if (modules.length) {
          return { name: modules[0].id };
        }
        return { name: 'files' };
      },
    },
    {
      path: '/share',
      component: () => import(/* webpackChunkName: "share" */ '@/components/Share.vue'),
      beforeEnter: permissionGuard('manage_shared_folder'),
      children: [
        {
          path: '',
          name: 'share',
          component: () => import(/* webpackChunkName: "shareFiles" */ '@/components/share/shareFiles.vue'),
        },
        {
          path: 'tracking',
          name: 'tracking',
          component: () => import(/* webpackChunkName: "tracking" */ '@/components/share/tracking.vue'),
        },
      ],
      meta: {
        cachedChildRoute: null,
      },
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: () => import(/* webpackChunkName: "contacts" */ '@/components/Contacts.vue'),
      beforeEnter: permissionGuard('manage_shared_folder'),
    },
    {
      path: '/files/:bc*',
      name: 'files',
      component: () => import(/* webpackChunkName: "files" */ '@/components/Files.vue'),
      beforeEnter: permissionGuard('manage_files'),
    },
    {
      path: '/activity-log',
      name: 'activity-log',
      component: () => import(/* webpackChunkName: "activity-log" */ '@/components/ActivityLog.vue'),
      beforeEnter: permissionGuard('manage_shared_folder'),
    },
    {
      path: '/automations',
      redirect: { name: 'file-operations' },
    },
    {
      path: '/automations/internal',
      component: () => import(/* webpackChunkName: "automations" */ '@/components/Automations.vue'),
      beforeEnter: permissionGuard('manage_automation'),
      children: [
        {
          path: '',
          name: 'file-operations',
          component: () =>
            import(/* webpackChunkName: "fileOperationList" */ '@/components/automation/FileOperationList.vue'),
        },
        {
          path: 'autodeletes',
          name: 'autodeletes',
          component: () =>
            import(/* webpackChunkName: "autodeleteList" */ '@/components/automation/autodeleteList.vue'),
        },
        {
          path: 'user-autodelete',
          name: 'user-autodelete',
          component: () =>
            import(/* webpackChunkName: "autoDeleteUsersList" */ '@/components/automation/AutoDeleteUsersList.vue'),
          beforeEnter: permissionGuard('manage_user'),
        },
        {
          path: 'contact-autodelete',
          name: 'contact-autodelete',
          component: () =>
            import(
              /* webpackChunkName: "autoDeleteContactsList" */ '@/components/automation/AutoDeleteContactsList.vue'
            ),
          beforeEnter: permissionGuard('manage_user'),
        },
        {
          path: 'activity-export',
          name: 'activity-export',
          component: () =>
            import(/* webpackChunkName: "activityExportList" */ '@/components/automation/activityExportList.vue'),
        },
      ],
      meta: {
        cachedChildRoute: null,
      },
    },
    {
      path: '/automations/external',
      component: () => import(/* webpackChunkName: "externalAutomations" */ '@/components/ExternalAutomations.vue'),
      beforeEnter: permissionGuard('manage_automation'),
      children: [
        {
          path: '',
          name: 'external',
          component: () =>
            import(
              /* webpackChunkName: "externalAutomationsList" */ '@/components/automation/ExternalAutomationsList.vue'
            ),
        },
        {
          path: 'remote-site/:action?/:remoteId?',
          name: 'remote-site',
          component: () =>
            import(/* webpackChunkName: "remoteSiteList" */ '@/components/automation/RemoteSiteList.vue'),
        },
        {
          path: 'remote-agent',
          name: 'remote-agent',
          component: () =>
            import(/* webpackChunkName: "remoteAgentList" */ '@/components/automation/RemoteAgentList.vue'),
        },
      ],
      meta: {
        cachedChildRoute: null,
      },
    },
    {
      path: '/automations/schedule',
      name: 'schedule',
      component: () => import(/* webpackChunkName: "scheduleOverview" */ '@/components/ScheduleOverview.vue'),
      beforeEnter: permissionGuard('manage_automation'),
    },
    {
      path: '/administration',
      component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin.vue'),
      beforeEnter: permissionGuard(['manage_user', 'manage_account']),
      children: [
        {
          path: '',
          name: 'account-info',
          component: () => import(/* webpackChunkName: "accInfo" */ '@/components/administration/accInfo.vue'),
        },
        {
          path: 'users',
          name: 'users',
          component: () => import(/* webpackChunkName: "userList" */ '@/components/administration/userList.vue'),
          beforeEnter: permissionGuard('manage_user'),
        },
        {
          path: 'groups',
          name: 'groups',
          component: () => import(/* webpackChunkName: "groupList" */ '@/components/administration/GroupList.vue'),
          beforeEnter: permissionGuard('manage_group'),
        },
        {
          path: 'service-settings',
          name: 'service-settings',
          component: () =>
            import(/* webpackChunkName: "serviceSettings" */ '@/components/administration/serviceSettings.vue'),
          beforeEnter: permissionGuard('manage_account'),
        },
        {
          path: 'quarantine',
          name: 'quarantine',
          component: () =>
            import(/* webpackChunkName: "AccQuarantine" */ '@/components/administration/quarantine/AccQuarantine.vue'),
          beforeEnter: permissionGuard('manage_account'),
        },
        {
          path: 'sso',
          name: 'sso',
          component: () => import(/* webpackChunkName: "ssoList" */ '@/components/administration/sso/ssoList.vue'),
          beforeEnter: permissionGuard('manage_account'),
        },
      ],
      meta: {
        cachedChildRoute: null,
      },
    },
    {
      path: '/billing',
      component: () => import(/* webpackChunkName: "billing" */ '@/components/Billing.vue'),
      beforeEnter: permissionGuard('manage_billing'),
      children: [
        {
          path: '',
          name: 'billing',
          component: () => import(/* webpackChunkName: "invoiceList" */ '@/components/billing/invoiceList.vue'),
        },
        {
          path: 'cards',
          name: 'cards',
          component: () => import(/* webpackChunkName: "cardList" */ '@/components/billing/cardList.vue'),
        },
      ],
      meta: {
        cachedChildRoute: null,
      },
    },
    {
      path: '/inbox',
      name: 'inbox',
      beforeEnter(to, from, next) {
        if (store.state.profile.operationsCode !== 0) {
          next({ name: 'main' });
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "inbox" */ '@/components/Inbox.vue'),
    },
    {
      path: '/profile',
      beforeEnter(to, from, next) {
        if (!store.state.loggedAsContact) {
          next();
        } else {
          next({ name: 'main' });
        }
      },
      component: () => import(/* webpackChunkName: "profile" */ '@/components/Profile.vue'),
      children: [
        {
          path: '',
          name: 'settings',
          component: () => import(/* webpackChunkName: "generalSettings" */ '@/components/profile/generalSettings.vue'),
        },
        {
          path: 'security',
          name: 'security',
          component: () =>
            import(/* webpackChunkName: "securitySettings" */ '@/components/profile/securitySettings.vue'),
        },
        {
          path: 'sftp',
          name: 'sftp',
          component: () => import(/* webpackChunkName: "sftpSettings" */ '@/components/profile/sftpSettings.vue'),
          beforeEnter(to, from, next) {
            if (store.state.profile.services.some((service) => service.service_key === 'SFTP')) {
              next();
            } else {
              next(from);
            }
          },
        },
        {
          path: 'api-keys',
          name: 'api-keys',
          component: () => import(/* webpackChunkName: "apiKeysSettings" */ '@/components/profile/apiKeysSettings.vue'),
        },
      ],
      meta: {
        cachedChildRoute: null,
      },
    },
    {
      path: '/download/:id?',
      name: 'download',
      component: () => import(/* webpackChunkName: "download" */ '@/components/DownloadPage.vue'),
      meta: {
        isStandAlone: true,
        rulesKey: 'downloadPage',
      },
    },
    {
      path: '/set_password/:id?',
      name: 'set-password',
      component: () => import(/* webpackChunkName: "set-password" */ '@/components/SetPassword.vue'),
      meta: {
        isStandAlone: true,
        rulesKey: 'passwordPage',
      },
    },
    {
      path: '/reset_password/:id?',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "reset-password" */ '@/components/ResetPassword.vue'),
      meta: {
        isStandAlone: true,
        rulesKey: 'passwordPage',
      },
    },
    {
      path: '/files-return/:id?',
      name: 'files-return',
      component: () => import(/* webpackChunkName: "return-files" */ '@/components/ReturnPage.vue'),
      meta: {
        isStandAlone: true,
        rulesKey: 'returnPage',
      },
    },
    {
      path: '/key_request/:id?',
      name: 'key-request',
      component: () => import(/* webpackChunkName: "key-requests" */ '@/components/KeyRequest.vue'),
      meta: {
        isStandAlone: true,
        rulesKey: 'keyRequestPage',
      },
    },
    {
      path: '/msft/:id?',
      name: 'msft',
      component: () => import(/* webpackChunkName: "msft" */ '@/components/msft/msftPage.vue'),
      meta: {
        isStandAlone: true,
        rulesKey: 'msft',
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // read query params
  if (Object.keys(to.query).length) {
    // set email from query params for predefining on login form
    if (to.query.email) {
      store.commit('auth/setUserEmail', to.query.email);
    }
    if (to.query.error) {
      store.commit('auth/setLoginError', `sso-${to.query.error}`);
    }
    if (to.query.secure3d) {
      store.commit('billing/setSecure3DCode', to.query.secure3d);
      if (to.name === 'billing') {
        next({ name: 'cards' });
        return;
      }
    }
    if (to.query.action === 'pt') {
      EventBus.$emit('showWelcome');
    }
    if (to.query.mfa) {
      store.commit('auth/setForcedMfaCode', +to.query.mfa);
    }
  }

  const isTargetStandAlone = Boolean(to.meta.isStandAlone);
  // FIXME: maybe figure out a way to make this less opaque.
  // See: https://dev.maytech.net/jira/browse/IS-9300
  if (isTargetStandAlone && to.params.id) {
    router.history.current.params.id = to.params.id;
  }
  store.commit('setIsStandAlonePage', isTargetStandAlone);

  //We show 404 error when came to standalone pages without valid uid
  if (isTargetStandAlone && !UUID_REGEX.test(to.params.id)) {
    store.commit('setErrorPageKey', 'errorNotFound');
  }

  if (!isTargetStandAlone) {
    store.commit('setRules', 'main');
  } else {
    // Change checkPermission and errorCodesTree relative to page type
    store.commit('setRules', to.meta.rulesKey);
    EventBus.$emit('changePage');
  }

  if (isTargetStandAlone) {
    if (store.state[to.meta.rulesKey]?.isPageAccessAllowed === false) {
      return;
    }
  } else {
    if (!store.state.auth.isLoggedIn) {
      return;
    }
  }

  EventBus.$emit('beforeChangeRoute');
  next();
});

router.afterEach((to) => {
  if (to.meta.isStandAlone) {
    // Avoid login page blicks while metadata did'nt respond yet
    if (store.state[to.meta.rulesKey]?.isPageAccessAllowed === null) {
      EventBus.$emit('beforeChangeRoute');
      return;
    }
  }
  EventBus.$emit('afterChangeRoute');
});

export default router;
