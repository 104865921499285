import { atob } from '@/elements/utils.js';
import { fetchDownloadPageMetadata } from '@/api';
import EventBus from '@/elements/eventBus.js';

const getInitialState = () => ({
  pgpPassphrase: null,
  isPageAccessAllowed: null,
  metadata: {
    isDownloadEncrypted: false,
    isPgpEnabled: false,
    subject: null,
    sender: {
      name: null,
      email: null,
    },
    userId: null,
    message: null,
    privateKey: null,
    shareType: null,
    folderId: null,
    isReturnPage: false,
  },
});

export default {
  namespaced: true,
  state: {
    welcome: {
      is_reply: false,
      sender_name: null,
      share_type: null,
      date: null,
    },
    ...getInitialState(),
  },
  mutations: {
    setMetadata(state, response) {
      Object.assign(state.metadata, response);
    },
    setWelcome(state, response) {
      Object.assign(state.welcome, response);
    },
    setPGPPassphrase(state, passphrase) {
      state.pgpPassphrase = passphrase;
    },
    setIsPageAcessAllowed(state, value) {
      state.isPageAccessAllowed = value;
    },
    resetState(state) {
      if (state.metadata.shareType !== 'P') {
        Object.assign(state, getInitialState());
      }
    },
  },
  actions: {
    async getMetadata({ state, commit, dispatch }, id) {
      try {
        const {
          data: {
            subject,
            user_name: userName,
            user_email: userEmail,
            pgp_encrypted: isDownloadEncrypted,
            pgp_enabled: isPgpEnabled,
            user_id: userId,
            sender_name: name,
            sender_email: email,
            private_key: privateKey,
            message,
            share_type: shareType,
            folder_id: folderId, // folder that contains files
            can_reply: isReturnPage,
          },
        } = await fetchDownloadPageMetadata(id);
        commit('setMetadata', {
          subject,
          message,
          sender: {
            name,
            email,
          },
          isPgpEnabled,
          isDownloadEncrypted,
          userId,
          shareType,
          privateKey: privateKey ? atob(privateKey) : null,
          folderId,
          isReturnPage,
        });
        commit('setIsPageAcessAllowed', true);

        if (state.metadata.shareType !== 'P' || userName || userEmail) {
          dispatch('profile/getProfile', {}, { root: true });
        } else {
          // Hide spinner on public download when after logout
          EventBus.$emit('afterChangeRoute');
        }
      } catch (error) {
        commit('setIsPageAcessAllowed', false);
        if (error?.response?.data?.details) {
          commit('setWelcome', error.response.data.details);
        }
        return Promise.reject(error);
      }
    },
  },
};
