import Vue from 'vue';
import moment from 'moment';

const getInitialState = () => ({
  id: null,
  isSuperAdmin: false,
  operationsCode: 0,
  operations: {},
  maxFileSize: null,
  filesPerShare: null,
  accountStatus: 'A',
  accountPlan: '',
  accountExpireDate: 0,
  homeId: '',
  shareTypes: {},
  isPGPEnabled: false,
  services: [],
  hasPGPKey: false,
  incomingId: '',
  outgoingId: '',
  messageSignature: '',
  authMethods: [],
  forcedAuth: [],
  sftpLogin: '',
  sftpURL: '',
  uniqueLogin: '',
  share_settings: {
    expiry_date_limit: null,
    request_message: '',
    share_message: '',
  },
  homePrefix: null,
  userLimit: 0,
  storageLimit: 0,
  totalReservations: 0,
  isWopiActive: true,
  isAgentTab: false,
  responseTime: null,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    getProfileData: (state) => (profile) => {
      return {
        ...(profile.name && { name: profile.name }),
        ...(state.isSuperAdmin && profile.email && { email: profile.email.toLowerCase() }),
        ...((profile.message_signature || profile.message_signature === '') && {
          message_signature: profile.message_signature,
        }),
        ...(profile.language && { language: profile.language }),
      };
    },
    checkOperations: (state, getters, rootState, rootGetters) => (...permissions) => {
      return rootGetters['operations/check']({ operations: state.operations, permissions });
    },
  },
  mutations: {
    setProfile(state, profile) {
      state.id = profile.id;
      state.isSuperAdmin = profile.super_admin === 'Y';
      if (profile.operations !== undefined) {
        state.operationsCode = profile.operations;
        state.operations = profile.parsedOperations;
      }
      state.maxFileSize = profile.max_file_size < 0 ? null : parseInt(profile.max_file_size, 10);
      state.accountStatus = profile.account_status;
      state.accountPlan = profile.plan;
      state.accountExpireDate = profile.expire_date;
      state.homeId = profile.home_id || '';
      state.services = profile.services;
      state.incomingId = profile.incoming_id;
      state.outgoingId = profile.outgoing_id;
      state.forcedAuth = profile.forced_auth;
      state.sftpLogin = profile.sftp_login;
      state.sftpURL = profile.sftp_url;
      state.uniqueLogin = profile.unique_login;
      state.share_settings = {
        expiry_date_limit: profile.share_settings?.expiry_date_limit,
        request_message: profile.share_settings?.request_message?.replaceAll('<br/>', '\n') || '',
        share_message: profile.share_settings?.share_message?.replaceAll('<br/>', '\n') || '',
      };
      if (profile.wopi_enabled !== undefined) {
        state.isWopiActive = profile.wopi_enabled;
      }
      state.isAgentTab = Boolean(profile.show_agent_tab);
      state.isExternalAutomationEnabled =
        profile.external_automation_enabled !== undefined ? profile.external_automation_enabled : true; // temporary it's true for everyone
      state.isShowAllPfMembers = Boolean(profile.show_all_pf_members); // true = show autocomplete on create/edit PF on input focus
    },
    setResponseTime(state, time) {
      state.responseTime = time;
    },
    setMessageSignature(state, message_signature) {
      state.messageSignature = message_signature;
    },
    setPGPState(state, pgp_state) {
      state.isPGPEnabled = pgp_state;
    },
    setPGPKeys(state, has_key) {
      state.hasPGPKey = has_key;
    },
    setAuthMethods(state, auth_methods) {
      state.authMethods = auth_methods;
    },
    setUserLimits(state, limits) {
      state.userLimit = parseInt(limits.user_limit, 10);
      state.storageLimit = parseInt(limits.storage_limit, 10);
      state.totalReservations = parseInt(limits.total_reservations, 10);
    },
    setHomePrefix(state, prefix) {
      state.homePrefix = prefix;
    },
    setFilesPerShare(state, filesPerShare) {
      state.filesPerShare = parseInt(filesPerShare, 10);
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    getProfile({ commit, dispatch, rootGetters }, options = {}) {
      API.accountProfile(({ status, getResponseHeader }, resp) => {
        if (status === 200) {
          // TODO: refactor data that should not belong to profile
          commit('setProfileName', resp.name, { root: true });
          commit('auth/setUserEmail', resp.email, { root: true });
          commit('setLoggedAsContact', resp.is_contact, { root: true });
          commit('setLanguage', resp.language, { root: true });
          commit('setPGPState', resp.pgp_enabled);
          commit('setPGPKeys', resp.has_key);
          commit('setMessageSignature', resp.message_signature);
          commit('setAuthMethods', resp.auth_methods);
          commit('share/setActiveShareTypes', resp.share_types || {}, { root: true });
          commit('setHomePrefix', resp.template);
          commit('setFilesPerShare', resp.max_files_per_share);
          dispatch('setupThirdPartExtensions', resp);
          if (resp.operations !== undefined) {
            resp.parsedOperations = rootGetters['operations/parse']('user', resp.operations);
            if (!resp.parsedOperations.manage_billing && rootGetters.isSuspended) {
              commit('setErrorPageKey', 'errorSuspended', { root: true });
            }
          }
          commit('setProfile', resp);
          commit('files/setRootModel', resp.home_id, { root: true });
          commit('setResponseTime', moment(getResponseHeader('Date'))); // for correct expiration limit
          commit('auth/setIsLoggedIn', true, { root: true });
          typeof options.success === 'function' && options.success();
        } else {
          commit('auth/setIsLoggedIn', false, { root: true });
          if (status === 404) {
            dispatch('logout', undefined, { root: true });
            resp.msg = Vue.prototype.$gettext("You can't access this account.");
          }
          return typeof options.error === 'function' && options.error(status, resp.code, resp);
        }
      });
    },
    setProfile({ getters, commit }, options = {}) {
      API.updateAccountProfile(getters.getProfileData(options.data), ({ status }, resp) => {
        if (status === 200) {
          commit('setProfileName', resp.name, { root: true });
          commit('auth/setUserEmail', resp.email, { root: true });
          commit('setLanguage', resp.language, { root: true });
          commit('setMessageSignature', resp.message_signature);
          typeof options.success === 'function' && options.success();
        } else {
          return typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    setupThirdPartExtensions(store, profile) {
      if (typeof Sentry !== 'undefined') {
        Sentry.setUser({
          email: profile.email,
          id: profile.id,
          data: profile,
        });
      }
      if (typeof UserVoice !== 'undefined') {
        UserVoice.push([
          'identify',
          {
            email: profile.email,
            id: profile.id,
          },
        ]);
        UserVoice.push([
          'set',
          {
            locale: profile.language === 'zh_CN' ? 'cn' : 'en-GB',
            strings: {
              contact_title: Vue.prototype.$gettext('Contact us'),
              contact_message_placeholder: Vue.prototype.$gettext(
                'Report an issue, suggest a new feature or leave us your feedback.'
              ),
            },
          },
        ]);
      }
    },
  },
};
