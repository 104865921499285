import { sortItems } from '@/elements/utils.js';

const getInitialState = () => ({
  invoice: {},
  invoiceToPay: null,
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    setInvoice(state, invoice) {
      state.invoice = invoice;
    },
    setInvoiceToPay(state, id) {
      state.invoiceToPay = id;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    getInvoice({ commit }, options = {}) {
      API.getInvoice(options.id, ({ status }, invoice) => {
        if (status === 200) {
          invoice.vat = parseFloat(invoice.vat) * 100;
          invoice.items = sortItems(invoice.items, ['order_num', 'package_name', 'description']);
          commit('setInvoice', invoice);
          typeof options.success === 'function' && options.success();
        } else {
          typeof options.error === 'function' && options.error();
        }
      });
    },
    payInvoice({ state, commit }, options = {}) {
      const invoiceId = options.id || state.invoiceToPay;
      const onPaymentCompleted = ({ status }, resp) => {
        if (status === 200) {
          commit('setInvoiceToPay', null);
          typeof options.success === 'function' && options.success();
        } else {
          return typeof options.error === 'function' && options.error(status, resp);
        }
      };
      if (options.newCard) {
        API.payInvoiceByNewCard(invoiceId, options.data, onPaymentCompleted);
      } else {
        API.payInvoice(invoiceId, options.data || { card_id: null }, onPaymentCompleted);
      }
    },
  },
};
