import Vue from 'vue';
import { filesize, convertUnits, getFormatPrice, sortItems, currencies } from '@/elements/utils.js';
import { fetchPurchaseMetadata, getPackagesForUpgrade, countPrice } from '@/api';
import EventBus from '@/elements/eventBus.js';

const getInitialState = () => ({
  currency: '',
  renewalPeriod: '',
  currentPackageId: null,
  newMainPackageId: null,
  isTrial: null,
  packages: [],
  customPackages: [],
  packagesForUpgrade: [],
  packagesFromPurchase: [],
  pricing: {
    '1 mon': [],
    '1 year': [],
  }, // prices for full year by renewal_periods
  upgradePricing: [], // prices for upgrade period
  currentPeriodPrice: null, // price for current period upgrade
  secure3DCode: null,
  isChangesToSave: false,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    getItemLabel: () => (key, label) => {
      const labels = {
        max_users: Vue.prototype.$gettext('User licences'),
        disc_quota: Vue.prototype.$gettext('Storage'),
      };
      return labels[key] || label;
    },
    getItemType: () => (count, type) => {
      const item_types = {
        max_users: Vue.prototype.$ngettext('user', 'users', count),
        disc_quota: 'GB',
      };
      return item_types[type];
    },
    getItemUnits: (state) => (value, name) => {
      const isBytes = ['disc_quota'].includes(name);
      return isBytes ? filesize(convertUnits(value, 'GB', 'B')) : value;
    },
    getPrice: (state) => (id, item_id, type, renewal_period = state.renewalPeriod) => {
      let prices;
      switch (type) {
        case 'upgrade':
          prices = state.upgradePricing;
          break;
        default:
          prices = state.pricing[renewal_period] || [];
      }
      return prices.find((price) => {
        return (
          (id ? price.package_id === id : !Boolean(price.package_id)) &&
          (item_id ? price.package_item_id === item_id : !Boolean(price.package_item_id))
        );
      });
    },
    getFormattedPrice: (state) => (price) => {
      if (typeof price === 'number') {
        return currencies[state.currency] + getFormatPrice(price);
      } else {
        return null;
      }
    },
    getItemPrice: (state, getters) => (
      id,
      item_id,
      formatted = true,
      type = 'new',
      renewal_period = state.renewalPeriod
    ) => {
      const pack = getters.getPrice(id, item_id, type, renewal_period);
      const price = pack ? pack.price[state.currency].total_period_price : 0;
      return formatted ? getters.getFormattedPrice(price) : price;
    },
    getItemDiscount: (state, getters) => (id, item_id, type = 'new', renewal_period = state.renewalPeriod) => {
      const pack = getters.getPrice(id, item_id, type, renewal_period);
      const price = pack ? pack.price[state.currency].total_discount : 0;
      return price;
    },
    getPricePerItem: (state, getters) => (
      id,
      item_id,
      item_type,
      formatted = true,
      type = 'new',
      renewal_period = state.renewalPeriod
    ) => {
      const pack = getters.getPrice(id, item_id, type, renewal_period);
      const price = pack ? pack.price[state.currency].price : 0;
      if (!price) {
        return null;
      }
      return formatted ? `${getters.getFormattedPrice(price)}/${getters.getItemType(1, item_type)}` : price;
    },
    getItemValue: (state, getters) => (id, item_id) => {
      const pack = getters.getPrice(id, item_id);
      if (pack) {
        return pack.value;
      }
    },
    getItemTotalValue: (state, getters) => (id, item_id, item_type) => {
      const pack = getters.getPrice(id, item_id);
      if (pack) {
        return `${pack.total_value} ${item_type ? getters.getItemType(pack.total_value, item_type) : ''}`;
      }
    },
    getPackageStructure: (state) => (pack) => {
      const result = [];
      const getPack = (pack) => {
        let res = { id: pack.id };
        if (pack.items) {
          res.items = pack.items.map((item) => {
            return {
              id: item.id,
              value: item.value ? parseInt(item.value, 10) : item.value,
            };
          });
        }
        result.push(res);
        if (pack.children) {
          pack.children
            .filter((child) => child.isActive)
            .forEach((child) => {
              getPack(child);
            });
        }
      };
      getPack(pack);
      return result;
    },
    getPackDescription: (state) => (text, columns) => {
      if (text) {
        const desc = text.split(', ');
        if (desc.length) {
          const itemsInOneCol = Math.round(desc.length / columns);
          const res = [];
          while (desc.length) {
            res.push(desc.splice(0, itemsInOneCol));
          }
          return res;
        }
      } else {
        return [];
      }
    },
  },
  mutations: {
    setSecure3DCode(state, code) {
      state.secure3DCode = code;
    },
    setPackages(state, payload) {
      const packages = sortItems(payload.packages, ['order_num']);
      packages.map((pack) => {
        if (pack.items) {
          pack.items = sortItems(pack.items, ['order_num']);
        }
      });
      const mainPack = packages.find((pack) => pack.id === payload.main_id);
      if (mainPack) {
        const mainPackIndex = packages.indexOf(mainPack);
        packages.splice(mainPackIndex, 1);
        state.packages = [mainPack].concat(packages);
      } else {
        state.packages = packages;
      }
    },
    setCustomPackages(state, packages) {
      state.customPackages = packages;
    },
    setPackagesForUpgrade(state, packages) {
      state.packagesForUpgrade = packages;
    },
    setPackagesFromPurchase(state, packages) {
      state.packagesFromPurchase = packages;
    },
    setCurrency(state, currency) {
      state.currency = currency;
    },
    setRenewalPeriod(state, renewalPeriod) {
      state.renewalPeriod = renewalPeriod;
    },
    setCurrentPackageId(state, id) {
      state.currentPackageId = id;
    },
    setNewMainPackageId(state, id) {
      state.newMainPackageId = id;
    },
    setIsTrial(state, is_trial) {
      state.isTrial = is_trial;
    },
    setPackagePricing(state, payload) {
      Vue.set(state, 'pricing', { ...state.pricing, [payload.renewal_period]: payload.pricing });
    },
    setPackageUpgradePricing(state, pricing) {
      state.upgradePricing = pricing;
    },
    setCurrentPeriodPrice(state, price) {
      state.currentPeriodPrice = price < 0 ? 0 : price; // prices lower than zero interpret as zero
    },
    setIsChangesToSave(state, status) {
      state.isChangesToSave = status;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getPurchaseMetadata({ state, commit, dispatch }) {
      try {
        const { data } = await fetchPurchaseMetadata();
        commit('setCurrency', data.currency);
        commit('setRenewalPeriod', data.renewal_period);
        commit('setPackages', { packages: data.packages, main_id: data.main_package_id });
        commit('setIsTrial', state.packages[0].is_trial);
        commit('setCustomPackages', data.custom_packages);
        commit('setCurrentPackageId', data.main_package_id);
        commit('setNewMainPackageId', data.main_package_id);
        await dispatch('getPackagesForUpgrade');
        return Promise.resolve({
          canUpgrade: state.isTrial || data.expiration_date * 1000 > new Date().getTime(),
        });
      } catch (error) {
        if (error && error.response) {
          EventBus.$emit('handle-error', { response: error.response });
        }
        return Promise.reject(error);
      }
    },
    async getPackagesForUpgrade({ state, commit }, options = {}) {
      const changePackage = (item) => {
        if (item.items) {
          item.items = sortItems(item.items, ['order_num']);
        }
        if (item.children) {
          item.children.forEach((child) => {
            child.isActive = true;
            changePackage(child);
          });
        }
      };
      const setPurchaseValues = (pack) => {
        const purchasePack = state.packages.find((purchase) => purchase.id === pack.id);
        if (purchasePack) {
          if (pack.items) {
            pack.items.forEach((item) => {
              if (item.customizable) {
                const purchaseItem = purchasePack.items.find((pitem) => pitem.id === item.id);
                if (purchaseItem) {
                  item.value = purchaseItem.value;
                }
              }
            });
          }
          if (pack.children) {
            pack.children.forEach((child) => setPurchaseValues(child));
          }
        }
      };
      const getPackagesFromPurchase = (mainPack) => {
        const getMainPackageFlat = () => {
          let result = [];
          const getPack = (pack) => {
            result.push(pack.id);
            if (pack.children) {
              pack.children.forEach((child) => {
                getPack(child);
              });
            }
          };
          getPack(mainPack);
          return result;
        };
        const flatPacks = getMainPackageFlat();
        const flatPurchase = state.packages.map((pack) => pack.id);
        const difference = flatPurchase.filter((pack) => !flatPacks.includes(pack));
        if (difference.length) {
          commit(
            'setPackagesFromPurchase',
            state.packages.filter((pack) => difference.includes(pack.id))
          );
        }
      };
      try {
        let { data } = await getPackagesForUpgrade();
        data = data || [];
        if (data.length) {
          data = sortItems(data, ['order_num']);
          data.forEach((item) => changePackage(item));

          const mainPackage = data.find((pack) => pack.id === state.currentPackageId);

          if (mainPackage) {
            setPurchaseValues(mainPackage);
            getPackagesFromPurchase(mainPackage);
          }
        }
        commit('setPackagesForUpgrade', data);
        return Promise.resolve();
      } catch (error) {
        if (error && error.response) {
          EventBus.$emit('handle-error', { response: error.response });
        }
        return Promise.reject(error);
      }
    },
    async getPrice({ state, commit }, options = {}) {
      const body = {
        main_package_id: options.main_package_id,
        packages: options.packages,
        ...(options.currency && { currency: options.currency }),
        ...(options.renewal_period && { renewal_period: options.renewal_period }),
      };
      try {
        const { data } = await countPrice(body);
        commit('setPackagePricing', {
          pricing: data.new_price,
          renewal_period: options.renewal_period || state.renewalPeriod,
        });
        if (!options.silent) {
          commit('setPackageUpgradePricing', data.upgrade_price);
        }
        return Promise.resolve();
      } catch (error) {
        if (error && error.response) {
          EventBus.$emit('handle-error', { response: error.response });
        }
        return Promise.reject(error);
      }
    },
  },
};
