import Vue from 'vue';
import moment from 'moment';
import { MOMENT_FORMATS } from '@/utils/cons.js';
import { getCards, setAutoRenewal } from '@/api';

const getInitialState = () => ({
  cards: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parseCard: (state) => (resp) => {
      resp.expiry_date = moment.utc(resp.expiry_date * 1000).format(MOMENT_FORMATS.CARD_EXPIRY_DATE);
      const [month, year] = resp.expiry_date.split('/');
      resp.expiry_sort = `${year}${month}`;
      resp.number = '*** ' + resp.last_digits;
      resp.isActive = true;
      // Checked statuses in table
      resp.isDisabledState = false;
      resp.isCheckedState = false;
      resp.isActiveState = false;
      return resp;
    },
    getAttributes: (state) => (attr) => {
      return {
        ...(attr.id && { id: attr.id }),
        ...(attr.expiry_date && { expiry_date: attr.expiry_date }),
        ...(attr.token && { token: attr.token }),
        ...(attr.number && { number: attr.number.replace(/\s/g, '') }),
        ...{ name: attr.name || '' },
        ...(attr.holder_name && { holder_name: attr.holder_name }),
        ...(attr.security_code && { security_code: attr.security_code }),
        ...(attr.billing_address && {
          billing_address: {
            ...(attr.billing_address.address1 && { address1: attr.billing_address.address1 }),
            ...(attr.billing_address.address2 && { address2: attr.billing_address.address2 }),
            ...(attr.billing_address.address3 && { address3: attr.billing_address.address3 }),
            ...(attr.billing_address.city && { city: attr.billing_address.city }),
            ...(attr.billing_address.zip && { zip: attr.billing_address.zip }),
            ...(attr.billing_address.country_code && { country_code: attr.billing_address.country_code }),
            ...(attr.billing_address.country_state && { country_state: attr.billing_address.country_state }),
          },
        }),
        ...(attr.type && { type: attr.type }),
        ...(typeof attr.auto_renew !== 'undefined' && { auto_renew: attr.auto_renew }),
        ...(typeof attr.isActive !== 'undefined' && { status: attr.isActive ? 'A' : 'D' }),
        ...(attr.invoice_id && { invoice_id: attr.invoice_id }),
        ...(attr.strong_customer_authentication && {
          strong_customer_authentication: {
            browser_javascript_enabled: true,
            browser_java_enabled: navigator.javaEnabled(),
            browser_language: navigator.language,
            browser_color_depth: screen.colorDepth.toString(),
            browser_screen_height: screen.height.toString(),
            browser_screen_width: screen.width.toString(),
            browser_tz: new Date().getTimezoneOffset().toString(),
          },
        }),
      };
    },
    getCardById: (state) => (id) => {
      return state.cards.find((card) => card.id === id);
    },
  },
  mutations: {
    setCards(state, cards) {
      state.cards = cards;
    },
    addCard(state, card) {
      state.cards.push(card);
    },
    updateCard(state, card) {
      const cardIndex = state.cards.findIndex((item) => item.id === card.id);
      const { isActiveState, isCheckedState, isDisabledState } = state.cards[cardIndex];
      const updatedCard = { ...card, isCheckedState, isActiveState, isDisabledState };
      Vue.set(state.cards, cardIndex, updatedCard);
    },
    deleteCard(state, card_id) {
      state.cards.forEach((s_card, index) => {
        if (s_card.id === card_id) {
          state.cards.splice(index, 1);
        }
      });
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
    setAutopaymentCard(state, auto_payment_card) {
      const cardId = auto_payment_card || state.cards.find((card) => card.auto_payment_card)?.auto_payment_card;
      state.cards = state.cards.map((card) => ({ ...card, isAutoPaymentCard: card.id === cardId }));
    },
  },
  actions: {
    async getCardsAsync({ getters, commit }) {
      const resp = await getCards();
      if (resp?.data?.length > 0) {
        commit(
          'setCards',
          resp.data.map((card) => getters['parseCard'](card))
        );
        commit('setAutopaymentCard');
      }
      return resp;
    },
    //TODO: remove old getCards action and use getCardsAsync instead
    getCards({ getters, commit }, options = {}) {
      API.getCards(({ status }, resp) => {
        if (status === 200) {
          let cards = resp.map((card) => getters['parseCard'](card));
          commit('setCards', cards);
          commit('setAutopaymentCard');
          typeof options.success === 'function' && options.success();
        } else {
          typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    getCard({ getters, commit }, options = {}) {
      API.getCard(options.id, ({ status }, resp) => {
        if (status === 200) {
          const newCard = getters['parseCard'](resp);
          commit('updateCard', newCard);
          typeof options.success === 'function' && options.success();
        } else {
          typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    addCard({ getters, commit }, options = {}) {
      API.createCard(getters['getAttributes'](options.data), ({ status }, resp) => {
        if (status === 201 || status === 200) {
          const newCard = getters['parseCard'](resp);
          commit('addCard', newCard);
          typeof options.success === 'function' && options.success();
        } else {
          return typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    editCard({ getters, commit }, options = {}) {
      API.editCard(options.data.id, getters['getAttributes'](options.data), ({ status }, resp) => {
        if (status === 200) {
          const newCard = getters['parseCard'](resp);
          commit('updateCard', newCard);
          typeof options.success === 'function' && options.success();
        } else {
          typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    deleteCard({ commit }, options = {}) {
      API.deleteCard(options.id, ({ status }, resp) => {
        if (status === 200) {
          commit('deleteCard', options.id);
          typeof options.success === 'function' && options.success();
        } else {
          return typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    async setAutoRenewal({ commit }, data) {
      const resp = await setAutoRenewal(data);
      if (resp) {
        commit('setAutopaymentCard', resp.data.auto_payment_card);
      }
      return resp;
    },
  },
};
