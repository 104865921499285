import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import Vue from 'vue';
import Notifications from 'vue-notification/dist/ssr.js';
import VueMeta from 'vue-meta';
import { BootstrapVue } from 'bootstrap-vue';
import PortalVue from 'portal-vue';

import App from '@/App.vue';
import store from '@/store';
import router from '@/router.js';
import sanitizeHTML from '@/utils/sanitizer.js';
import '@/utils/cryptoPolyfill.js';
import '@/utils/translationInjector.js';
import '@/utils/sentryClient.js';
import '@/utils/validation/rules';

Vue.use(Notifications);
Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(PortalVue);

Vue.prototype.$sanitize = sanitizeHTML;

new Vue({
  //NOSONAR
  el: '#app',
  router,
  store,
  components: { App },
  render(h) {
    return h('App');
  },
});
