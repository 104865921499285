import axios from 'axios';
import axiosRetry from 'axios-retry';

const BASE_URL = '/api/1.0';

const http = axios.create({
  baseURL: BASE_URL,
  timeout: 0,
  headers: {
    common: {
      'Cache-Control': 'no-store',
    },
  },
});

const prepareURL = (url) => BASE_URL + url;

//If you want to turn off retries for some request, pass { 'axios-retry': { retries: 0 } } in request
axiosRetry(http, {
  retries: 2,
  retryCondition: (error) => axiosRetry.isNetworkOrIdempotentRequestError(error) || (!error.response && !error.status),
  retryDelay: (retryCount) => retryCount * 2000,
});

http.interceptors.request.use(
  (config) => {
    //While axios adding every time baseUrl to request url we need to remove it when we retry request
    config.url = config.url.replace(BASE_URL, '');

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { http, prepareURL };
