var Support = function () {
  // Const
  var WINDOWS = 'windows', MAC = 'mac', LIN = 'linux',
    SHOCKWAVE_FLASH = "Shockwave Flash",
    FLASH_MIME_TYPE = "application/x-shockwave-flash",
    SHOCKWAVE_FLASH_AX = "ShockwaveFlash.ShockwaveFlash",
    OBJECT = "object";

  // File API
  this.File = !!window.File;
  this.FileReader = !!window.FileReader
  this.FileList = !!window.FileList
  this.Blob = !!window.Blob;
  // File upload
  this.xhrFileUpload = !!(window.XMLHttpRequest && this.FileReader);
  this.xhrFormDataFileUpload = !!window.FormData;

  this.directoryUpload = (function (argument) {
    var el = document.createElement('input'),
      directory;
    el.type = 'file';
    return typeof el.webkitdirectory !== "undefined" || typeof el.directory !== "undefined" || typeof el.mozdirectory !== "undefined";
  }());

  this.cryptoRandom = !!(window.crypto && window.crypto.getRandomValues);

  this.typedArrays = !!(window.ArrayBuffer && window.Uint8Array);

  this.webWorkers = typeof window.Worker !== 'undefined';

  this.blobs = typeof window.Blob !== 'undefined';

  this.decryption = this.cryptoRandom && this.typedArrays && this.webWorkers && this.blobs;

  // DOM
  this.w3cdom = !!(document.getElementById && document.getElementsByTagName && document.createElement);

  this.userAgent = navigator.userAgent.toLowerCase();
  var platform = navigator.platform.toLowerCase();


  // Platform
  if (platform ? /win/.test(platform) : /win/.test(this.userAgent))
    this.platform = WINDOWS;
  else if (platform ? /mac/.test(platform) : /mac/.test(this.userAgent))
    this.platform = MAC;
  else if (platform ? /lin/.test(platform) : /lin/.test(this.userAgent))
    this.platform = LIN;
  else
    this.platform = undefined;

  // Engine
  this.webkit = /webkit/.test(this.userAgent) ? parseFloat(this.userAgent.replace(/^.*webkit\/(\d+(\.\d+)?).*$/, "$1")) : false, // returns either the webkit version or false if not webkit

    this.isIE = !+"\v1"; // feature detection based on Andrea Giammarchi's solution: http://webreflection.blogspot.com/2009/01/32-bytes-to-know-if-your-browser-is-ie.html

  // Flash detection
  this.FlashPlayerVersion = [0, 0, 0];
  var description = null;
  //          plugin = false;
  if (typeof navigator.plugins != undefined && typeof navigator.plugins[SHOCKWAVE_FLASH] == OBJECT) {
    description = navigator.plugins[SHOCKWAVE_FLASH].description;
    if (description && !(typeof navigator.mimeTypes != undefined && navigator.mimeTypes[FLASH_MIME_TYPE] && !navigator.mimeTypes[FLASH_MIME_TYPE].enabledPlugin)) { // navigator.mimeTypes["application/x-shockwave-flash"].enabledPlugin indicates whether plug-ins are enabled or disabled in Safari 3+
      //          plugin = true;
      //          ie = false; // cascaded feature detection for Internet Explorer
      description = description.replace(/^.*\s+(\S+\s+\S+$)/, "$1");
      this.FlashPlayerVersion[0] = parseInt(description.replace(/^(.*)\..*$/, "$1"), 10);
      this.FlashPlayerVersion[1] = parseInt(description.replace(/^.*\.(.*)\s.*$/, "$1"), 10);
      this.FlashPlayerVersion[2] = /[a-zA-Z]/.test(description) ? parseInt(description.replace(/^.*[a-zA-Z]+(.*)$/, "$1"), 10) : 0;
    }
  }
  else if (typeof window.ActiveXObject != undefined) {
    try {
      var a = new ActiveXObject(SHOCKWAVE_FLASH_AX);
      if (a) { // a will return null when ActiveX is disabled
        description = a.GetVariable("$version");
        if (description) {
          //              ie = true; // cascaded feature detection for Internet Explorer
          description = description.split(" ")[1].split(",");
          this.FlashPlayerVersion = [parseInt(description[0], 10), parseInt(description[1], 10), parseInt(description[2], 10)];
        }
      }
    }
    catch (e) { }
  }
}

const support = new Support()

export default support;