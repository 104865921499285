const BASE_URL = window.location.origin + '/api/1.0';

const _requestFunction = ({ url, headers = {}, data = {}, method = 'GET' }, callback) => {
  if (!window.jQuery) {
    throw new Error('requestFunction require jQuery');
  }
  const options = {
    url: url.includes(window.location.origin) ? url : BASE_URL + url,
    type: method,
    data,
    beforeSend: (xhr) => {
      xhr.setRequestHeader('Cache-Control', 'no-store');
      if (headers) {
        for (let key in headers) {
          if (headers.hasOwnProperty(key)) {
            xhr.setRequestHeader(key, headers[key]);
          }
        }
      }
    },
    complete: (xhr, status) => {
      typeof callback === 'function' && callback(xhr, status);
    },
  };

  if (method === 'POST') {
    options.contentType = 'application/json; charset=UTF-8';
    options.data = JSON.stringify(options.data);
  }

  return window.jQuery.ajax(options);
};

const API = {
  timeout: 0,
  _request(args, count = 0) {
    let counter = count;
    return _requestFunction(args, (xhr) => {
      this.onApiCall && this.onApiCall(args, xhr);

      if (xhr.status === 0 && !args.noRetry) {
        counter++;
        if (counter < 3) {
          setTimeout(() => {
            this._request(args, counter);
          }, 100);
          return;
        }
      }

      if (typeof args.callback === 'function') {
        const isJSON = /json/.test(xhr.getResponseHeader('Content-Type'));
        const response = isJSON ? JSON.parse(xhr.responseText) : xhr.responseText;

        if (xhr.status >= 400 && !args.silent) {
          this.onApiError(args, xhr, response, () => args.callback(xhr, response));
        } else {
          args.callback(xhr, response);
        }
      }
    });
  },
  /*  To avoid caching on invoice download, preview, where we open new window
    with that url(native get request) we can't pass header, so we still need add
    timestamp to bu sure that invoice or preview will be fresh
  */
  prepareUrl(path) {
    return `${BASE_URL + path}?_=${Date.now()}`;
  },
  getApiUrl() {
    return BASE_URL;
  },
  onApiError(args, xhr, resp, callback) {
    return typeof callback === 'function' && callback();
  },
};

API.defaultAccountLang = function(callback) {
  return API._request({
    method: 'GET',
    url: '/languages/default',
    callback,
  });
};

API.unblockCaptcha = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/session/unblock-captcha',
    data,
    callback,
  });
};

API.shareAction = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/share/create',
    data,
    callback,
  });
};

API.requestFiles = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/share/request',
    data,
    callback,
  });
};

API.fileMetadata = function(resource, data, callback) {
  return API._request({
    method: 'GET',
    url: '/file/metadata/' + resource,
    data,
    callback,
  });
};

API.getDownloadLink = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/file/download-link',
    data,
    callback,
  });
};

API.filecmdCopy = function(source, target, callback) {
  var data = {
    ids: source,
    target: target,
  };

  return API._request({
    method: 'POST',
    url: '/file/copy',
    data,
    callback,
  });
};

API.fileRename = function(id, name, callback) {
  return API._request({
    method: 'POST',
    url: '/file/rename/' + id,
    data: {
      name: name,
    },
    callback,
  });
};

API.filecmdMove = function(source, target, callback) {
  return API._request({
    method: 'POST',
    url: '/file/move',
    data: {
      ids: source,
      target: target,
    },
    callback,
  });
};

API.fileDelete = function(ids, callback) {
  return API._request({
    method: 'POST',
    url: '/file/delete',
    data: {
      ids: ids,
    },
    callback,
  });
};

API.createQuicklink = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/quicklink/create',
    data,
    callback,
  });
};

API.getUsers = function(callback) {
  return API._request({
    method: 'GET',
    url: '/user',
    callback,
  });
};

API.userMetadata = function(uuuid, callback) {
  return API._request({
    method: 'GET',
    url: '/user/metadata/' + uuuid,
    callback,
  });
};

API.userCreate = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/user/create',
    data,
    callback,
  });
};

API.userEdit = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/user/edit',
    data,
    callback,
  });
};

API.userDelete = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/user/delete',
    data,
    callback,
  });
};

API.getUserInfo = function(callback) {
  return API._request({
    method: 'GET',
    url: '/profile/info',
    callback,
  });
};

API.groups = function(callback) {
  return API._request({
    method: 'GET',
    url: '/group',
    callback,
  });
};

API.groupMetadata = function(uuid, callback) {
  return API._request({
    method: 'GET',
    url: '/group/metadata/' + uuid,
    callback,
  });
};

API.groupCreate = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/group/create',
    data,
    callback,
  });
};

API.groupEdit = function(uuid, data, callback) {
  return API._request({
    method: 'POST',
    url: '/group/edit/' + uuid,
    data,
    callback,
  });
};

API.groupDelete = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/group/delete',
    data,
    callback,
  });
};

API.accountProfile = function(callback) {
  return API._request({
    method: 'GET',
    url: '/profile',
    callback,
  });
};

API.updateAccountProfile = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/profile/set',
    data,
    callback,
  });
};

// File preview
API.previewFile = function(fuuid, callback) {
  return API._request({
    method: 'GET',
    url: '/file/preview/' + fuuid,
    callback,
  });
};

API.getFileInfo = function(fuuid, callback) {
  return API._request({
    method: 'GET',
    url: '/file/info/' + fuuid,
    callback,
  });
};

API.getFolderSize = function(fuuid, callback) {
  return API._request({
    method: 'GET',
    url: '/file/size/' + fuuid,
    callback,
  });
};

// Recipients
API.getRecipients = function(callback) {
  return API._request({
    method: 'GET',
    url: '/share/recipients',
    callback,
  });
};

// Contacts
API.getContacts = function(callback) {
  return API._request({
    method: 'GET',
    url: '/contact',
    callback,
  });
};

API.getContactMetadata = function(uuid, callback) {
  return API._request({
    method: 'GET',
    url: '/contact/metadata/' + uuid,
    callback,
  });
};

API.createContact = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/contact/create',
    data,
    callback,
  });
};

API.updateContact = function(cuuid, data, callback) {
  return API._request({
    method: 'POST',
    url: '/contact/edit/' + cuuid,
    data,
    callback,
  });
};

API.deleteContacts = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/contact/delete',
    data,
    callback,
  });
};

API.getInbox = function(data, callback) {
  return API._request({
    method: 'GET',
    url: '/inbox',
    data,
    callback,
  });
};

API.getActivity = function(data, callback) {
  return API._request({
    method: 'GET',
    url: '/tracking/activity',
    data,
    callback,
  });
};

API.getTrackingDaily = function(uid, day, callback) {
  return API._request({
    method: 'GET',
    url: '/tracking/' + uid + '?date=' + day,
    callback,
  });
};

API.getTrackingCSV = function(data, callback) {
  return API._request({
    method: 'GET',
    url: '/tracking/csv',
    data,
    callback,
  });
};

API.getSiteSettings = function(callback) {
  return API._request({
    method: 'GET',
    url: '/settings',
    callback,
  });
};

API.editSiteSettings = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/settings/set',
    data,
    callback,
  });
};

API.getAuthMethods = function(callback) {
  return API._request({
    method: 'GET',
    url: '/settings/auth-methods',
    callback,
  });
};

// PGP Keys Management

API.getUserKeys = function(uuid, callback) {
  return API._request({
    method: 'GET',
    url: '/user/pgp-key/' + uuid,
    callback,
    silent: true,
  });
};

API.deleteKey = function(uuid, callback) {
  return API._request({
    method: 'GET',
    url: '/pgp-key/delete/' + uuid,
    callback,
  });
};

API.updateKey = function(uuid, data, callback) {
  return API._request({
    method: 'POST',
    url: '/pgp-key/edit/' + uuid,
    data,
    callback,
  });
};

API.createKey = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/pgp-key/create',
    data,
    callback,
  });
};

API.userRequestPasswordReset = function(email, callback) {
  return API._request({
    method: 'POST',
    url: '/reset-password/request',
    data: { email: email },
    callback,
  });
};

API.requestPasswordReset = function(email, callback) {
  return API._request({
    method: 'POST',
    url: '/reset-password/request',
    data: { email: email },
    callback,
  });
};

API.abortMq = function() {
  if (API._mq && API._mq.state() === 'pending') {
    API._mq.abort();
  }
};

API.mqReq = function({ callback, callback_options }, l_m, etag) {
  // l_m - last modified date
  const headers = {};

  if (l_m) {
    headers['If-Modified-Since'] = l_m;
  }

  if (etag) {
    headers['If-None-Match'] = etag;
  }

  API._mq = _requestFunction({ url: `${window.location.origin}/mq/req`, method: 'GET', headers }, (xhr, status) => {
    // Restart itself, except when aborted (status == 'abort')
    if (xhr.status || status !== 'abort') {
      if (xhr.status == 200) {
        API.timeout = 0;
      } else {
        API.timeout += 1000;
      }
      setTimeout(() => {
        return API.mqReq(
          { callback, callback_options },
          xhr.getResponseHeader('Last-Modified'),
          xhr.getResponseHeader('Etag')
        );
      }, API.timeout);
    }

    typeof callback === 'function' && callback(callback_options, xhr);
  });
  return API._mq;
};

API.keepAlive = function(callback) {
  return API._request({
    method: 'GET',
    url: '/session/keepalive',
    callback,
  });
};
/* end of Reset Password*/

/* Project folders */
API.getProjectFolderList = function(callback) {
  return API._request({
    method: 'GET',
    url: '/project-folder',
    callback,
  });
};

API.getUsersProjectFolder = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/project-folder/projects-users',
    data,
    callback,
  });
};

API.setUsersProjectFolder = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/project-folder/set-users',
    data,
    callback,
  });
};

API.getProjectFolderMetadata = function(uuid, callback) {
  return API._request({
    method: 'GET',
    url: '/project-folder/metadata/' + uuid,
    callback,
  });
};

API.createProjectFolder = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/project-folder/create',
    data,
    callback,
  });
};

API.getProjectFolderUsers = function(uuid, callback) {
  return API._request({
    method: 'GET',
    url: '/project-folder/users/' + uuid,
    callback,
  });
};

API.addProjectFolderUsers = function(uuid, data, callback) {
  return API._request({
    method: 'POST',
    url: '/project-folder/add-users/' + uuid,
    data,
    callback,
  });
};

API.editProjectFolderUsers = function(uuid, data, callback) {
  return API._request({
    method: 'POST',
    url: '/project-folder/edit-users/' + uuid,
    data,
    callback,
  });
};

API.deleteProjectFolderUsers = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/project-folder/delete-users',
    data,
    callback,
  });
};

API.deleteProjectFolder = function(uuid, callback) {
  return API._request({
    method: 'GET',
    url: '/project-folder/delete/' + uuid,
    callback,
  });
};
/* end of Project folders */

// billing

API.getInvoice = function(uuid, callback) {
  return API._request({
    method: 'GET',
    url: '/invoice/metadata/' + uuid,
    callback,
  });
};

API.payInvoice = function(uuid, data, callback) {
  return API._request({
    method: 'POST',
    url: '/invoice/pay/' + uuid,
    data,
    callback,
  });
};
API.payInvoiceByNewCard = function(uuid, data, callback) {
  return API._request({
    method: 'POST',
    url: '/invoice/card-pay/' + uuid,
    data,
    callback,
  });
};
// end of billing

API.purchaseUpgrade = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/purchase/upgrade',
    data,
    callback,
  });
};

// cards management
API.getCards = function(callback) {
  return API._request({
    method: 'GET',
    url: '/credit-card/',
    callback,
  });
};

API.getCard = function(uuid, callback) {
  return API._request({
    method: 'GET',
    url: '/credit-card/metadata/' + uuid,
    callback,
  });
};

API.createCard = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/credit-card/create/',
    data,
    callback,
  });
};

API.editCard = function(uuid, data, callback) {
  return API._request({
    method: 'POST',
    url: '/credit-card/edit/' + uuid,
    data,
    callback,
  });
};

API.deleteCard = function(id, callback) {
  return API._request({
    method: 'POST',
    url: '/credit-card/delete/' + id,
    callback,
  });
};

// SSH keys
API.sshCreate = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/ssh-key/create',
    data,
    callback,
  });
};
API.sshEdit = function(id, data, callback) {
  return API._request({
    method: 'POST',
    url: '/ssh-key/edit/' + id,
    data,
    callback,
  });
};
API.sshDelete = function(id, callback) {
  return API._request({
    method: 'POST',
    url: '/ssh-key/delete/' + id,
    callback,
  });
};
API.sshMetadata = function(id, callback) {
  return API._request({
    method: 'GET',
    url: '/ssh-key/metadata/' + id,
    callback,
  });
};
API.getSshKeys = function(callback) {
  return API._request({
    method: 'GET',
    url: '/ssh-key/',
    callback,
  });
};
API.getUserSshKeys = function(uuid, callback) {
  return API._request({
    method: 'GET',
    url: '/ssh-key/' + uuid,
    callback,
  });
};

// Services
API.getServices = function(callback) {
  return API._request({
    method: 'GET',
    url: '/service',
    callback,
  });
};
API.serviceMetadata = function(uuid, callback) {
  return API._request({
    method: 'GET',
    url: '/service/metadata/' + uuid,
    callback,
  });
};
API.serviceEdit = function(uuid, data, callback) {
  return API._request({
    method: 'POST',
    url: '/service/edit/' + uuid,
    data,
    callback,
  });
};

//branding
API.getBrandingSettings = function(callback) {
  return API._request({
    method: 'GET',
    url: '/branding/',
    callback,
  });
};
API.editBrandingSettings = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/branding/',
    data,
    callback,
  });
};
API.previewBranding = function(data, callback) {
  return API._request({
    method: 'POST',
    url: '/branding/preview',
    data,
    callback,
  });
};

API.generate2fa = function(callback) {
  return API._request({
    method: 'GET',
    url: '/profile/2fa/generate',
    callback,
  });
};

API.setMfa = function(setFor, data, callback) {
  return API._request({
    method: 'POST',
    url: '/' + setFor + '/set-mfa',
    data,
    callback,
  });
};

API.resetMfa = function(setFor, data, callback) {
  return API._request({
    method: 'POST',
    url: '/' + setFor + '/reset-mfa',
    data,
    callback,
  });
};

API.removeMfa = function(removeFor, data, callback) {
  return API._request({
    method: 'POST',
    url: '/' + removeFor + '/remove-mfa',
    data,
    callback,
  });
};

window.API = API;
