import { atob, btoa } from '@/elements/utils.js';
import openpgp from '@/libs/openpgp.min';

const getInitialState = () => ({
  id: null,
  name: '',
  public: null,
  private: null,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parse: (state) => (resp) => {
      return {
        ...resp,
        ...(resp.public && { public: atob(resp.public) }),
        ...(resp.private && { private: atob(resp.private) }),
      };
    },
    getAttributes: (state) => (attr) => {
      return {
        ...{ name: attr.name },
        ...(attr.public && { public: btoa(attr.public) }),
        ...(attr.private && { private: btoa(attr.private) }),
      };
    },
    parsedPublicKey: (state, getters) => (options = {}) => {
      const keys = getters._parseKey(options.public || state.public, options);
      if (keys) {
        for (let i = 0, l = keys.length; i < l; i++) {
          if (!keys[i].isPublic()) {
            return false;
          }
        }
      }
      return keys;
    },
    parsedPrivateKey: (state, getters) => (options = {}) => {
      const keys = getters._parseKey(state.private, options);
      if (keys) {
        for (let i = 0, l = keys.length; i < l; i++) {
          if (!keys[i].isPrivate()) {
            return false;
          }
        }
      }
      return keys;
    },
    _parseKey: (state) => (key, options = {}) => {
      let data = key;
      try {
        data = options.decoded ? key : atob(key);
      } catch (e) {
        return false;
      }
      const res = openpgp.key.readArmored(data);
      if (!res.keys.length) {
        return false;
      }
      return res.keys;
    },
  },
  mutations: {
    setPGPKeys(state, keys) {
      state.id = keys.id;
      state.name = keys.name;
      state.public = keys.public;
      state.private = keys.private;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    getUserKeys({ getters, commit, rootState }, options = {}) {
      API.getUserKeys(options.userId, ({ status }, resp) => {
        if (status === 200) {
          const key = getters['parse'](resp);
          commit('setPGPKeys', key);
          typeof options.success === 'function' && options.success(key);
        } else {
          return typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    addKeys({ getters, commit }, options = {}) {
      API.createKey(getters['getAttributes'](options.data), ({ status }, resp) => {
        if (status === 201) {
          const key = getters['parse'](resp);
          commit('setPGPKeys', key);
          typeof options.success === 'function' && options.success(key);
        } else {
          return typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    editKeys({ getters, commit }, options = {}) {
      API.updateKey(options.data.id, getters['getAttributes'](options.data), ({ status }, resp) => {
        if (status === 200) {
          const key = getters['parse'](resp);
          commit('setPGPKeys', key);
          typeof options.success === 'function' && options.success(key);
        } else {
          return typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
  },
};
