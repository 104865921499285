import Vue from 'vue';
import store from '@/store';
import { extend, validate } from 'vee-validate';
import moment from 'moment';
import isGlob from 'is-glob';
import { convertUnits, stringEqualsIgnoreCase } from '@/elements/utils.js';

//We can import all already defined rules from vee-validate once
//import * as defaultRules from 'vee-validate/dist/rules';
//But i think we need engine of validation even more then rules, so we can import only required fields
import { required, digits, max, min, min_value, max_value, is_not, oneOf } from 'vee-validate/dist/rules';
import {
  EMAIL_REG_EXP,
  FILE_NAME_HAS_DOTS_REG_EXP,
  FILE_NAME_HAS_SLASHES_REG_EXP,
  EMAIL_FOOTER_REGEX,
  IP_REGEX,
  URL_REGEX,
  CARD_NUMBER_REGEX,
  CARD_HOLDER_REGEX,
  ZIP_REGEX,
  SFTP_REGEX,
  HOST_REGEX,
  AZURE_BUCKET_NAME_REGEX,
  B2_BUCKET_NAME_REGEX,
  S3_BUCKET_NAME_REGEX,
  GCS_NO_DOTS_BUCKET_NAME_REGEX,
  GCS_WITH_DOTS_BUCKET_NAME_REGEX,
} from './regExps';
import {
  EMAIL_MAX_LENGTH,
  EMAIL_LOCALE_PART_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  PASSWORD_SCORED_MIN_LENGTH,
  PASSWORD_SCORED_MAX_LENGTH,
  PASSWORD_MIN_SCORE,
  USER_NAME_MAX_LENGTH,
  FILE_NAME_MAX_LENGTH,
  EMAIL_FOOTER_MAX_LENGTH,
  PROFILE_EMAIL_FOOTER_MAX_LENGTH,
  UPGRADE_MAX_USERS,
  MAX_SFTP_LOGIN_LENGTH,
  SUBJECT_MAX_LENGTH,
} from './cons.js';
import { filesize } from '@/elements/utils.js';
import checkSSHKey from './sshKeyValidation';
import { SUPPORT_EMAIL } from '@/utils/cons.js';
import { isSystemFolder, isProjectsFolder, isUserHomesFolder } from '@/utils/files.js';

extend('min_value', {
  ...min_value,
  message: (field, params) =>
    Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext('Should be %{ params.min } or more'), { params }),
});
extend('max_value', {
  ...max_value,
  message: (field, params) => {
    // automation delete users
    if (field === 'notify') {
      return Vue.prototype.$gettext('Should be equal or less then Inactive days');
    }
    return Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext('Should be %{ params.max } or less'), { params });
  },
});
extend('is_not', {
  ...is_not,
  message: () => Vue.prototype.$gettext("Can't be equal"),
});
extend('is', {
  validate: async (value, params) => {
    return value === params[0];
  },
  message: () => Vue.prototype.$gettext('Passwords should be equal'),
});

extend('max', {
  ...max,
  message: (field, { length }) =>
    Vue.prototype.$gettextInterpolate(
      Vue.prototype.$ngettext('Maximum %{ max } character allowed', 'Maximum %{ max } characters allowed', length),
      { max: length }
    ),
});

extend('min', {
  ...min,
  message: (field, { length }) =>
    Vue.prototype.$gettextInterpolate(
      Vue.prototype.$ngettext('Minlength - %{ min } symbol', 'Minlength - %{ min } symbols', length),
      { min: length }
    ),
});

extend('required', {
  ...required,
  message: () => Vue.prototype.$gettext('Required field'),
});

extend('digits', {
  ...digits,
  message: (field, params) =>
    Vue.prototype.$gettextInterpolate(
      Vue.prototype.$ngettext('Enter %{ length }-digit value', 'Enter %{ length }-digit value', params.length),
      { length: params.length }
    ),
});

extend('oneOf', {
  ...oneOf,
  message: () => Vue.prototype.$gettext('You can add items only from auto complete list'),
});

extend('email', {
  validate: async (value) => {
    if (!value) {
      return true;
    }
    const { valid, errors } = await validate(value, { max: EMAIL_MAX_LENGTH });
    if (!valid) {
      return errors[0];
    }
    if (EMAIL_REG_EXP.test(value)) {
      const localPart = value.indexOf('@');
      if (localPart > EMAIL_LOCALE_PART_MAX_LENGTH) {
        return Vue.prototype.$gettextInterpolate(
          Vue.prototype.$ngettext(
            'Local part should be less than %{ max } character',
            'Local part should be less than %{ max } characters',
            EMAIL_LOCALE_PART_MAX_LENGTH
          ),
          {
            max: EMAIL_LOCALE_PART_MAX_LENGTH,
          }
        );
      }
    } else {
      return Vue.prototype.$gettext('Email is incorrect');
    }
    return true;
  },
  computesRequired: true,
});

extend('password', {
  validate: async (value) => {
    const maxLengthValidation = await validate(value, { max: PASSWORD_MAX_LENGTH });
    if (!maxLengthValidation.valid) {
      return maxLengthValidation.errors[0];
    }
    const minLengthValidation = await validate(value, { min: PASSWORD_MIN_LENGTH });
    if (!minLengthValidation.valid) {
      return minLengthValidation.errors[0];
    }
    return true;
  },
  computesRequired: true,
});

extend('subject', {
  validate: async (value) => {
    const { valid, errors } = await validate(value, { max: SUBJECT_MAX_LENGTH });
    if (!valid) {
      return errors[0];
    }
    if (value.indexOf('@') !== -1) {
      return Vue.prototype.$gettext('The subject with symbol "@" is forbidden');
    }
    return true;
  },
});

extend('profile-name', {
  validate: async (value) => {
    const { valid, errors } = await validate(value, { max: USER_NAME_MAX_LENGTH });
    if (!valid) {
      return errors[0];
    }
    if (value.indexOf('@') !== -1) {
      return Vue.prototype.$gettext('Profile name with symbol "@" is forbidden');
    }
    if (value.indexOf('://') !== -1) {
      return Vue.prototype.$gettext('Profile name cannot contain special character sequence "://"');
    }
    return true;
  },
});

extend('file-name', {
  validate: async (value) => {
    const { valid, errors } = await validate(value, { max: FILE_NAME_MAX_LENGTH });
    if (!valid) {
      return errors[0];
    }
    if (FILE_NAME_HAS_DOTS_REG_EXP.test(value)) {
      return Vue.prototype.$gettext("'.' or '..' are forbidden");
    }
    if (FILE_NAME_HAS_SLASHES_REG_EXP.test(value)) {
      return Vue.prototype.$gettext('Slash and backslash are forbidden');
    }
    return true;
  },
});

extend('ssh-key', {
  validate: (value) => {
    if (!value) {
      return true;
    }
    if (checkSSHKey(value) === false) {
      return Vue.prototype.$gettext('Incorrect SSH key');
    }
    return true;
  },
});

extend('password-with-score', {
  validate: async (value) => {
    const checkScore = async (value) => {
      const zxcvbn = await import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn').then(({ default: module }) => module);
      const score = zxcvbn(value).score;
      return score >= PASSWORD_MIN_SCORE;
    };

    if (!value) {
      return true;
    }
    const maxLengthValidation = await validate(value, { max: PASSWORD_SCORED_MAX_LENGTH });
    if (!maxLengthValidation.valid) {
      return maxLengthValidation.errors[0];
    }
    const minLengthValidation = await validate(value, { min: PASSWORD_SCORED_MIN_LENGTH });
    if (!minLengthValidation.valid) {
      return minLengthValidation.errors[0];
    }

    if ((await checkScore(value)) === false) {
      return Vue.prototype.$gettext('Your password is too weak');
    }
    return true;
  },
  computesRequired: true,
});

extend('email-footer', {
  validate: async (value) => {
    if (value.length > EMAIL_FOOTER_MAX_LENGTH) {
      return Vue.prototype.$gettextInterpolate(
        Vue.prototype.$gettext('Email footer should be less than %{ max } characters long'),
        {
          max: EMAIL_FOOTER_MAX_LENGTH,
        }
      );
    }
    if (EMAIL_FOOTER_REGEX.test(value)) {
      return Vue.prototype.$gettext('Backslash is forbidden');
    }
    return true;
  },
});
extend('email-profile-footer', {
  validate: async (value) => {
    if (value.length > PROFILE_EMAIL_FOOTER_MAX_LENGTH) {
      return Vue.prototype.$gettextInterpolate(
        Vue.prototype.$gettext('Email footer should be less than %{ max } characters long'),
        {
          max: PROFILE_EMAIL_FOOTER_MAX_LENGTH,
        }
      );
    }
    if (EMAIL_FOOTER_REGEX.test(value)) {
      return Vue.prototype.$gettext('Backslash is forbidden');
    }
    return true;
  },
});

extend('ip', {
  validate: (value) => {
    if (!value) {
      return true;
    }
    if (!IP_REGEX.test(value)) {
      return Vue.prototype.$gettext('IP is incorrect');
    }
    return true;
  },
});

const units = ['B', 'KB', 'MB', 'GB', 'TB'];

extend('quota', {
  validate: (value, [quotaUnit]) => {
    const size = parseInt(value, 10);
    let maxQuota = store.state.profile.storageLimit;
    let biteSize;
    if (!value) {
      return true;
    }
    const fromIndex = units.indexOf(quotaUnit);
    if (~fromIndex) {
      biteSize = size * Math.pow(1000, fromIndex);
    }
    if (quotaUnit === 'U' || !Boolean(quotaUnit)) {
      return Vue.prototype.$gettext('Incorrect units');
    }
    if (isNaN(biteSize) || biteSize < 0) {
      return Vue.prototype.$gettext('Incorrect quota value');
    }
    if (biteSize > maxQuota) {
      return Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext('The value cannot be greater than %{ max }'), {
        max: filesize(maxQuota),
      });
    }
    return true;
  },
});
extend('reserved_storage', {
  validate: (
    value,
    [reservedStorageUnit, quota, quotaUnit, initReservedStorage, initReservedStorageUnit, usersSelected]
  ) => {
    const size = convertUnits(value, reservedStorageUnit, 'B');
    const initialSize = convertUnits(initReservedStorage, initReservedStorageUnit, 'B');
    const quotaSize = convertUnits(quota, quotaUnit, 'B');
    const reservationLimit = store.state.profile.storageLimit - store.state.profile.totalReservations;
    const currentLimit =
      +usersSelected > 1 ? Math.floor(reservationLimit / usersSelected) : initialSize + reservationLimit;
    const maxReservationValue = quotaSize <= currentLimit && quotaUnit !== 'U' ? quotaSize : currentLimit;
    if (!value) {
      return true;
    }
    if (isNaN(size) || size < 0) {
      return Vue.prototype.$gettext('Incorrect value');
    }
    if (size > maxReservationValue) {
      return Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext('The value cannot be greater than %{ max }'), {
        max: filesize(maxReservationValue),
      });
    }
    return true;
  },
});

extend('url', {
  validate: (value) => {
    if (!value) {
      return true;
    }
    if (!URL_REGEX.test(value)) {
      return Vue.prototype.$gettext('URL is incorrect');
    }
    return true;
  },
});

extend('card-number', {
  validate: (value) => {
    const cardNumber = value.replace(/\s/g, '');
    if (!CARD_NUMBER_REGEX.test(cardNumber)) {
      return Vue.prototype.$gettext('Credit card number is incorrect');
    }
    return true;
  },
});

extend('card-holder-name', {
  validate: (value) => {
    if (!CARD_HOLDER_REGEX.test(value)) {
      return Vue.prototype.$gettext('Incorrect name');
    }
    return true;
  },
});

extend('card-expiration', {
  validate: (value) => {
    const expirationDate = value.split('/');
    const month = parseInt(expirationDate[0], 10);
    const year = parseInt(expirationDate[1], 10);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = parseInt(
      currentDate
        .getFullYear()
        .toString()
        .slice(-2),
      10
    );
    if (!month || !year || month > 12 || (month < currentMonth && year === currentYear) || year < currentYear) {
      return Vue.prototype.$gettext('Expiry date is incorrect');
    }
    return true;
  },
});

extend('zip', {
  validate: (value) => {
    if (!value) {
      return true;
    }
    if (!ZIP_REGEX.test(value)) {
      return Vue.prototype.$gettext('Zip is incorrect');
    }
    return true;
  },
});

extend('max-users', {
  validate: (value) => {
    if (value > UPGRADE_MAX_USERS) {
      return Vue.prototype.$gettextInterpolate(
        Vue.prototype.$gettext('Please contact %{ openTag }Quatrix Customer Care%{ closeTag } for %{ max }+ licences'),
        {
          openTag: '<a href="mailto:' + SUPPORT_EMAIL + '">',
          closeTag: '</a>',
          max: UPGRADE_MAX_USERS,
        },
        true
      );
    }
    return true;
  },
});

extend('min-max-value', {
  validate: (minMaxValue, [minValue, maxValue, userLimitValue]) => {
    const value = parseInt(minMaxValue, 10);
    const min = parseInt(minValue, 10);
    const max = maxValue ? parseInt(maxValue, 10) : Infinity;
    const userLimit = parseInt(userLimitValue, 10);
    if (Number.isNaN(value)) {
      return Vue.prototype.$gettext('You can use only numbers');
    }
    if (value < min || value > max) {
      return Vue.prototype.$gettextInterpolate(
        Vue.prototype.$gettext(
          'You can add between %{ range } users. %{ breakTag } To add more, choose another package or contact %{ openTag }Quatrix Customer Care%{ closeTag }.'
        ),
        {
          breakTag: '<br/>',
          openTag: '<a href="mailto:' + SUPPORT_EMAIL + '">',
          closeTag: '</a>',
          range: min + ' - ' + max,
        },
        true
      );
    }
    if (value < userLimit) {
      return Vue.prototype.$gettextInterpolate(
        Vue.prototype.$ngettext(
          'Your account contains %{ count } user. To choose less users, delete some of them.',
          'Your account contains %{ count } users. To choose less users, delete some of them.',
          userLimit
        ),
        { count: userLimit }
      );
    }
    return true;
  },
});

extend('sftpLogin', {
  validate: async (value) => {
    if (!value) {
      return true;
    }
    const { valid, errors } = await validate(value, { max: MAX_SFTP_LOGIN_LENGTH });
    if (!valid) {
      return errors[0];
    }
    if (!SFTP_REGEX.test(value)) {
      return Vue.prototype.$gettext('Custom login is incorrect');
    }
    return true;
  },
});
extend('release-date', {
  validate: (releaseDate, expiryDate) => {
    const relDate = releaseDate && moment(releaseDate).startOf('minute');
    const expDate = expiryDate?.length && moment(expiryDate[0]).startOf('minute');
    const currentDate = moment(new Date()).startOf('minute');

    if (!relDate) {
      return true;
    }
    if (expDate && expDate <= relDate) {
      return Vue.prototype.$gettext('The release date should be earlier than the expiry date');
    }
    if (currentDate >= relDate) {
      return Vue.prototype.$gettext('The release time should be later than now');
    }
    return true;
  },
});

extend('is-glob', {
  validate: (value) => {
    if (!isGlob(value)) {
      return Vue.prototype.$gettext('Not a valid glob');
    }
    return true;
  },
});

// Helper for defining validators that check for a given permission.
const makePermissionValidator = (permission, errMessage) => ({
  validate: (fileId, [file]) => {
    // Exclude system folders from the check because their permissions are somewhat special.
    // See also the validator 'not-protected-system-folder'.
    if (isSystemFolder(file)) {
      return true;
    }

    // FIXME: Never check for the 'delete' or 'rename' on project folders.  This
    // is a kludge to mend the fact that ATM backend returns the permissions for
    // a project folder ITSELF and you can never see 'delete' or 'rename' there,
    // even if you actually can delete/rename files INSIDE OF that project
    // folder.
    if (file.isProjectFolder && ['delete', 'rename'].includes(permission)) {
      return true;
    }

    if (!store.getters['files/checkOperations']({ permissions: file.permissions }, permission)) {
      return errMessage;
    }
    return true;
  },
});

extend(
  'rename-permission',
  makePermissionValidator('rename', Vue.prototype.$gettext("You don't have permission to rename in the folder"))
);
extend(
  'upload-permission',
  makePermissionValidator('upload', Vue.prototype.$gettext("You don't have permission to upload into the folder"))
);
extend(
  'download-permission',
  makePermissionValidator('download', Vue.prototype.$gettext("You don't have permission to download from the folder"))
);
extend(
  'delete-permission',
  makePermissionValidator('delete', Vue.prototype.$gettext("You don't have permission to delete in the folder"))
);
extend(
  'mkdir-permission',
  makePermissionValidator(
    'mkdir',
    Vue.prototype.$gettext("You don't have permission to create folders inside the folder")
  )
);

extend('not-protected-system-folder', {
  validate: (fileId, [file]) => {
    if (isProjectsFolder(file) || isUserHomesFolder(file)) {
      return Vue.prototype.$gettext('This folder cannot be source or destination for automations');
    }
    return true;
  },
});

extend('external-destination-type', {
  validate: (value, [source, destination]) => {
    if (source === 'quatrix' && destination === 'quatrix') {
      return Vue.prototype.$gettext('You need to select a remote site as destination');
    }
    if (source !== 'quatrix' && destination !== 'quatrix') {
      return Vue.prototype.$gettext('You need to select the current account as destination');
    }
    return true;
  },
});

extend('unique-name', {
  validate: (value, list) => {
    if (list.includes(value)) {
      return Vue.prototype.$gettext('The name is already in use');
    }
    return true;
  },
});

extend('unique-name-ignore-case', {
  validate: (value, list) => {
    if (list.some((existingName) => stringEqualsIgnoreCase(existingName, value))) {
      return Vue.prototype.$gettext('The name is already in use');
    }
    return true;
  },
});

extend('min-age', {
  validate: async (minValue, [maxValue, minUnit, maxUnit]) => {
    if (!maxValue) {
      return true;
    }

    if (minUnit === maxUnit) {
      const { valid: notEqualValid, errors: notEqualErrors } = await validate(minValue, { is_not: maxValue });
      if (!notEqualValid) {
        return notEqualErrors[0];
      }
      const { valid: maxValueValid } = await validate(minValue, { max_value: maxValue });
      if (!maxValueValid) {
        return Vue.prototype.$gettext('Should be less than "Maximum"');
      }
    } else {
      const minAge = moment.duration(minValue, minUnit).asMilliseconds();
      const maxAge = moment.duration(maxValue, maxUnit).asMilliseconds();
      const minMaxDiff = maxAge - minAge;
      if (minMaxDiff < 0) {
        return Vue.prototype.$gettext('Should be less than "Maximum"');
      }
      if (minMaxDiff === 0) {
        return Vue.prototype.$gettext("Can't be equal");
      }
    }
    return true;
  },
});

extend('min-size', {
  validate: async (minValue, [maxValue, minUnit, maxUnit]) => {
    if (!maxValue) {
      return true;
    }
    if (minUnit === maxUnit) {
      const { valid: notEqualValid, errors: notEqualErrors } = await validate(minValue, { is_not: maxValue });
      if (!notEqualValid) {
        return notEqualErrors[0];
      }
      const { valid: maxValueValid } = await validate(minValue, { max_value: maxValue });
      if (!maxValueValid) {
        return Vue.prototype.$gettext('Should be less than "Maximum"');
      }
    } else {
      const units = ['b', 'k', 'M', 'G'];
      const minIndex = units.indexOf(minUnit);
      const maxIndex = units.indexOf(maxUnit);
      const minSizeInBytes = minValue * Math.pow(1000, minIndex);
      const maxSizeInBytes = maxValue * Math.pow(1000, maxIndex);
      if (minSizeInBytes === maxSizeInBytes) {
        return Vue.prototype.$gettext("Can't be equal");
      }
      if (maxSizeInBytes < minSizeInBytes) {
        return Vue.prototype.$gettext('Should be less than "Maximum"');
      }
    }
    return true;
  },
});

extend('host', {
  validate: (value) => {
    if (!HOST_REGEX.test(value)) {
      return Vue.prototype.$gettext('Hostname is incorrect');
    }
    return true;
  },
});

extend('isJSON', {
  validate: (value) => {
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return Vue.prototype.$gettext('Incorrect JSON format');
    }
  },
});

extend('azure-bucket-name', {
  validate: async (value) => {
    const { valid, errors } = await validate(value, {
      min: 3,
      max: 63,
    });
    if (!valid) {
      return errors[0];
    }
    if (!AZURE_BUCKET_NAME_REGEX.test(value)) {
      return Vue.prototype.$gettext('Container name is incorrect');
    }
    return true;
  },
});

extend('b2-bucket-name', {
  validate: async (value) => {
    const { valid, errors } = await validate(value, {
      min: 6,
      max: 50,
    });
    if (!valid) {
      return errors[0];
    }
    if (!B2_BUCKET_NAME_REGEX.test(value)) {
      return Vue.prototype.$gettext('Bucket name is incorrect');
    }
    return true;
  },
});

extend('s3-bucket-name', {
  validate: async (value) => {
    const { valid, errors } = await validate(value, {
      min: 3,
      max: 63,
    });
    if (!valid) {
      return errors[0];
    }
    if (value.endsWith('s3alias') || !S3_BUCKET_NAME_REGEX.test(value) || IP_REGEX.test(value)) {
      return Vue.prototype.$gettext('Bucket name is incorrect');
    }
    return true;
  },
});

extend('gcs-bucket-name', {
  validate: async (value) => {
    if (value.includes('.')) {
      const { valid, errors } = await validate(value, {
        min: 3,
        max: 222,
      });
      if (!valid) {
        return errors[0];
      }
      if (!GCS_WITH_DOTS_BUCKET_NAME_REGEX.test(value) || IP_REGEX.test(value)) {
        return Vue.prototype.$gettext('Bucket name is incorrect');
      }
    } else {
      const { valid, errors } = await validate(value, {
        min: 3,
        max: 63,
      });
      if (!valid) {
        return errors[0];
      }
      if (!GCS_NO_DOTS_BUCKET_NAME_REGEX.test(value)) {
        return Vue.prototype.$gettext('Bucket name is incorrect');
      }
    }
    return true;
  },
});

extend('autocomplete_value', {
  validate: async (value, autocomplete_list) => {
    let match = '';
    autocomplete_list.forEach((text) => {
      if (text === value) {
        match = value;
        return;
      }
    });
    if (match.length === 0) {
      return Vue.prototype.$gettext('You can add items only from auto complete list');
    }
    return true;
  },
});
