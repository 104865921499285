export default {
  namespaced: true,
  state: {
    user: {
      defaults: {
        manage_files: false, // 1
        manage_shared_folder: false, // 2
        manage_automation: false, // 3
        manage_user: false, // 4
        manage_group: false, // 5
        manage_site_properties: false, // 6
        manage_branding: false, // 7
        manage_contact: false, // 8
        manage_account: false, // 9
        manage_billing: false, // 10
        manage_site_contact: false, // 11
      },
      masks: {
        manage_files: 1, // 1
        manage_shared_folder: 1 << 1, // 2
        manage_automation: 1 << 2, // 3
        manage_user: 1 << 3, // 4
        manage_group: 1 << 4, // 5
        manage_site_properties: 1 << 5, // 6
        manage_branding: 1 << 6, // 7
        manage_contact: 1 << 7, // 8
        manage_account: 1 << 8, // 9
        manage_billing: 1 << 9, // 10
        manage_site_contact: 1 << 10, // 11
      },
      grouped: {
        administrator: [
          'manage_files',
          'manage_shared_folder',
          'manage_automation',
          'manage_user',
          'manage_group',
          'manage_site_properties',
          'manage_branding',
          'manage_contact',
          'manage_account',
          'manage_site_contact',
        ],
        user: ['manage_files'],
        billing: ['manage_billing'],
      },
    },
    file: {
      defaults: {
        upload: false, // 1
        download: false, // 2
        mkdir: false, // 3
        delete: false, // 4
        rename: false, // 5
        share: false, // 6
        automation: false, // 7
        quicklink: false, // 8
        comments: false, // 9,
        preview: false, // 10,
        manage_pf: false, // 11,
        list: true, // 12
      },
      masks: {
        upload: 1, // 1
        download: 1 << 1, // 2
        mkdir: 1 << 2, // 3
        delete: 1 << 3, // 4
        rename: 1 << 4, // 5
        share: 1 << 5, // 6
        automation: 1 << 6, // 7
        quicklink: 1 << 7, // 8
        comments: 1 << 8, // 9,
        preview: 1 << 9, // 10
        manage_pf: 1 << 10, // 11
        list: 1 << 11, // 12
      },
    },
  },
  getters: {
    check: (state) => ({ operations, permissions }) => {
      if (!operations) {
        return;
      }
      const getFlat = (permission) => [].concat(...permission);
      const perm = getFlat(permissions);
      let permission;
      for (let i = 0, l = perm.length; i < l; i++) {
        permission = operations[perm[i]];
        if (!permission) {
          return false;
        }
      }
      return true;
    },
    parse: (state) => (type, resp) => {
      if (!type || !state[type]) {
        return;
      }
      let attributes = {};
      if (state[type].masks) {
        Object.keys(state[type].masks).forEach((permission) => {
          const mask = state[type].masks[permission];
          attributes[permission] = (resp & mask) !== 0;
        });
      }
      // Also set the grouped permission
      if (state[type].grouped) {
        Object.keys(state[type].grouped).forEach((group) => {
          const permissions = state[type].grouped[group];
          attributes[group] = permissions.reduce((group, permission) => group && attributes[permission], true);
        });
      }
      return attributes;
    },
    getOperationsByGroups: (state) => (type, groups) => {
      let operations = { ...state[type].defaults };
      groups.forEach((group) => {
        const groupPermissions = state[type].grouped[group];
        if (!groupPermissions) {
          return;
        } else {
          groupPermissions.forEach((permission) => (operations[permission] = true));
        }
      });
      return operations;
    },
    getAttributes: (state) => (type, operations) => {
      let permissions = 0;

      Object.keys(state[type].masks).forEach((permission) => {
        // If permission is true - set the bit
        if (operations[permission]) {
          permissions = permissions | state[type].masks[permission];
        }
      });

      return permissions;
    },
  },
};
