import Vue from 'vue';
import { getFileSize, formatTimestamp } from '@/elements/utils';
import { getTagsForPath, getTagsForFile, getCommentsInFolder, getFileComments, getFileMetadata } from '@/api';

const getInitialState = () => ({
  clipboard: null,
  fileList: [],
  trashModel: {},
  rootModel: {},
  selectedColumns: {
    tags: false,
    comments: false,
  },
  insidePF: false,
});

const DIR_TYPES = ['D', 'T', 'S'];

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    filesIds: (state) => state.fileList.map(({ id }) => id),
    parseModel: (state, getters, rootState, rootGetters) => (file, parseModel) => {
      if (DIR_TYPES.includes(file.type)) {
        file.isDir = true;
      }
      if (file.type === 'D') {
        file.isRegularFolder = true;
      }
      if (file.type === 'S') {
        file.isProjectFolder = true;
      }

      if (file.created !== undefined && file.createdFormatted === undefined) {
        file.createdFormatted = formatTimestamp(file.created);
      }
      if (file.modified !== undefined) {
        file.modifiedFormatted = formatTimestamp(file.modified);
      }

      if (file.name) {
        file.isEncrypted = file.name.slice(-4) === '.enc';
      }
      if (typeof file.size === 'number') {
        // Store bytes in other property
        file.bytes = file.size;
        // Format size
        file.size = getFileSize(file.type, file.bytes);
      }

      if (typeof file.operations === 'number') {
        //TODO: move operations to utils instead of vuex-module cause it is static
        file.permissions = rootGetters['operations/parse']('file', file.operations);
      }

      if (!file.id) {
        throw new Error("Can't find file id property");
      }

      if (file.isListed === undefined) {
        file.isListed = true;
      }

      file.isActive = true;
      file.isActiveState = false;
      file.isCheckedState = false;
      file.isDisabledState = false;
      file.tags = file.tags || null;

      //If we have some specif parseModel logic it has higher priority
      if (parseModel && typeof parseModel === 'function') {
        const parsedModel = parseModel(file);
        if (parsedModel) {
          return parsedModel;
        }
      }

      return file;
    },
    getFileById: (state) => (id) => state.fileList.find((file) => file.id === id),
    getFilesByIds: (state) => (ids) => state.fileList.filter((file) => ids.includes(file.id)),
    checkOperations: (state, getters, rootState, rootGetters) => (file, ...permissions) =>
      rootGetters['operations/check']({ operations: file.permissions, permissions }),
    getTmpModel: () => ({
      id: null,
      created: '',
      modified: '',
      name: 'default name',
      parent_id: null,
      size: '0 B',
      type: 'D',
      sub_type: '',
      metadata: {},
      permissions: null,
      content: [],
      bytes: 0,
      isDir: false,
      isListed: true,
      isActive: true,
      // Checked statuses in table
      isDisabledState: false,
      isCheckedState: false,
      isActiveState: false,
    }),
  },
  mutations: {
    setInsidePF(state, value) {
      state.insidePF = value;
    },
    setSelectedColumns(state, { tags = state.selectedColumns.tags, comments = state.selectedColumns.comments }) {
      state.selectedColumns = {
        tags,
        comments,
      };
    },
    matchTagsWithFiles(state, tags) {
      state.fileList.forEach((file, index) => {
        const tagsFromList = tags.find((tag) => file.id === tag.file_id);
        const fileTags = tagsFromList ? tagsFromList.tags.map((el) => el.value) : [];
        Vue.set(state.fileList, index, { ...file, tags: fileTags });
      });
    },
    matchCommentsWithFiles(state, comments) {
      state.fileList.forEach((file, index) => {
        const fileComments = comments.find((comment) => file.id === comment.file_id);
        Vue.set(state.fileList, index, { ...file, comments: fileComments ? fileComments.number : 0 });
      });
    },
    setClipboard(state, item) {
      state.clipboard = item;
    },
    setFiles(state, files) {
      state.fileList = files;
    },
    setTrashModel(state, trash) {
      state.trashModel = trash;
    },
    setRootModel(state, rootId) {
      state.rootModel = {
        id: rootId,
        name: '/',
        type: 'D',
        disabled: true,
        isListed: true,
      };
    },
    addFile(state, file) {
      state.fileList.push(file);
    },
    addArrayOfFiles(state, files) {
      state.fileList = state.fileList.concat(files);
    },
    clearFiles(state) {
      state.fileList = [];
    },
    updateFile(state, file) {
      const fileIndex = state.fileList.findIndex((item) => item.id === file.id);
      if (fileIndex !== -1) {
        const { isListed, isActiveState, isCheckedState, isDisabledState, tags } = state.fileList[fileIndex];
        const updatedFile = {
          ...state.fileList[fileIndex],
          ...file,
          isListed,
          isCheckedState,
          isActiveState,
          isDisabledState,
          ...(!file.tags && { tags }),
        };
        Vue.set(state.fileList, fileIndex, updatedFile);
      }
    },
    deleteFiles(state, files) {
      files.forEach((file) => {
        const id = file.id || file;
        const fileIndex = state.fileList.findIndex((item) => item.id === id);
        Vue.delete(state.fileList, fileIndex);
      });
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getTags({ state, commit }, id) {
      if (!state.selectedColumns.tags) {
        return;
      }
      try {
        const { data } = await getTagsForPath(id);
        commit('matchTagsWithFiles', data || []);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async getComments({ state, commit }, id) {
      if (!state.selectedColumns.comments) {
        return;
      }
      try {
        const { data } = await getCommentsInFolder(id);
        commit('matchCommentsWithFiles', data || []);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async getFiles({ commit }, id) {
      const { data: file } = await getFileMetadata(id);

      commit('clearFiles');

      //If it is file, not a directory
      if (file.type === 'F') {
        return { file };
      }

      // content - all files inside dir at first level
      // dir - data of current dir
      const { content: files, ...dir } = file;

      const trashModel = files.find((item) => item.type === 'T');
      if (trashModel) {
        commit('setTrashModel', trashModel);
      }

      //Add current dir to list of files
      dir.isListed = false;
      files.unshift(dir);

      return { file, files };
    },
    getFile({ getters, commit, dispatch }, options = {}) {
      API.fileMetadata(options.dir, { content: 0 }, async ({ status }, file) => {
        if (status === 200) {
          let parsedFile = getters.parseModel(file);
          if (parsedFile.permissions.upload) {
            parsedFile.tags = await dispatch('getFileTags', file.id);
          }
          if (parsedFile.permissions.comments) {
            parsedFile.comments = await dispatch('getFileCommentsCount', file.id);
          }
          if (parsedFile.type === 'T') {
            commit('setTrashModel', parsedFile);
          }
          if (getters.getFileById(file.id)) {
            commit('updateFile', parsedFile);
          } else {
            commit('addFile', parsedFile);
          }
          typeof options.success === 'function' && options.success(file);
        } else {
          typeof options.error === 'function' && options.error(status, file);
        }
      });
    },
    async getFileMetadata({ getters, commit }, id) {
      const { data: file } = await getFileMetadata(id, false);
      const parsedFile = getters.parseModel(file);

      if (parsedFile.type === 'T') {
        commit('setTrashModel', parsedFile);
      }
      if (getters.getFileById(parsedFile.id)) {
        commit('updateFile', parsedFile);
      } else {
        commit('addFile', parsedFile);
      }

      return parsedFile;
    },
    async getFileTags({ state }, id) {
      if (!state.selectedColumns.tags) {
        return [];
      }
      try {
        const data = (await getTagsForFile(id)) || {};
        return data.map(({ value }) => value);
      } catch (error) {
        Sentry.captureException(error);
        return [];
      }
    },
    async getFileCommentsCount({ state }, id) {
      if (!state.selectedColumns.comments) {
        return 0;
      }
      try {
        const { data } = (await getFileComments(id)) || {};
        return (data || []).length;
      } catch (error) {
        Sentry.captureException(error);
        return 0;
      }
    },
    downloadFile({ rootState }, options = {}) {
      API.getDownloadLink({ ids: options.list }, ({ status }, resp) => {
        if (status === 200) {
          const a = document.createElement('a');
          a.id = 'hiddenDownloader';
          a.href = API.prepareUrl('/file/download/' + resp.id);
          a.download = options.name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else if (status === 404) {
          return {
            errorMsg:
              resp.code === 90
                ? Vue.prototype.$gettext('You cannot download this folder because it is empty')
                : Vue.prototype.$gettext('File not found. Refresh file list and try again.'),
          };
        }
      });
    },
  },
};
