import { http } from './httpModule.js';
import { mfaLoginInterceptor, mfaLoginErrorInterceptor, lookupInterceptor } from './inteceptors';

// Profile

export const fetchProfile = () => http.get('/profile');

export const fetchProfileInfo = () => http.get('/profile/info', { validateStatus: (status) => status === 200 });

export const get2faCodes = () => http.get('/profile/2fa/get-code', { validateStatus: (status) => status === 200 });

export const setPassword = (data) =>
  http.post('/profile/set-password', data, { validateStatus: (status) => status === 200 });

export const getProfileInfo = () => http.get('/profile/info', { validateStatus: (status) => status === 200 });

// Session

export const logout = (params) =>
  http.get('/session/logout', { params, validateStatus: (status) => [200, 404].includes(status) });

export const accountMetadata = () => http.get('/account/metadata', { validateStatus: (status) => status === 200 });

export const getLanguageList = () => http.get('/languages', { validateStatus: (status) => status === 200 });

export const signIn = (token, show_tour) =>
  http
    .get('/session/login', {
      headers: {
        Authorization: `Basic ${token}`,
      },
      params: {
        ...(show_tour && { show_tour }),
        sb: 1,
      },
      validateStatus: (status) => [200, 207].includes(status),
    })
    .then(mfaLoginInterceptor)
    .catch(mfaLoginErrorInterceptor);

export const mfaLogin = (data) =>
  http
    .post('/session/login', data, {
      params: { sb: 1 },
      validateStatus: (status) => [200, 207].includes(status),
    })
    .then(mfaLoginInterceptor)
    .catch(mfaLoginErrorInterceptor);

export const keepAlive = () => http.get('/session/keepalive');

// MQ

// Get statuses of multiple jobs with IDs form the given array. For completed
// jobs, notification payload is also returned (the same payload that goes
// trough MQ).
export const getJobStatuses = (ids) => http.post('/job/status', { ids }, { validateStatus: (status) => status === 200 });

// Download page

export const fetchDownloadPageMetadata = (id) =>
  http.get(`/share/download-info/${id}`, { validateStatus: (status) => status === 200 });

export const fetchDownloadAllFilesLink = (id) =>
  http.get(`/share/download-link/${id}`, { validateStatus: (status) => status === 200 });

export const fetchAccountsList = () => http.get('/account/list');

export const fetchFiles = (id) => http.get(`/share/files/${id}`, { validateStatus: (status) => status === 200 });

export const fetchDownloadLink = (id, payload) =>
  http.post(`/share/download-link/${id}`, { files: payload }, { validateStatus: (status) => status === 200 });

export const sharePin = (data) => http.post('/share/login-pin/', data, { validateStatus: (status) => status === 200 });

export const shareLookup = (id, token) =>
  http
    .get(`/share/lookup/${id}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
      validateStatus: (status) => status === 200,
    })
    .then(lookupInterceptor);

// Key request page

export const fetchKeyRequestMetadata = (id) =>
  http.get(`/key-request/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const sendKeys = (id, payload) =>
  http.post(`/key-request/respond/${id}`, payload, { validateStatus: (status) => status === 202 });

export const keyRequestLookup = (id, token) =>
  http
    .get(`/key-request/lookup/${id}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
      validateStatus: (status) => status === 200,
    })
    .then(lookupInterceptor);

// Quarantine

export const getVirusFiles = () => http.get('/virusscan/files', { validateStatus: (status) => status === 200 });

export const markSafeVirusFiles = (payload) =>
  http.post('/virusscan/restore', payload, { validateStatus: (status) => status === 200 });

export const getVirusNotifyEmails = () => http.get('/virusscan/emails', { validateStatus: (status) => status === 200 });

export const editVirusNotifyEmails = (payload) =>
  http.post('/virusscan/emails', payload, { validateStatus: (status) => status === 200 });

// SSO

export const getSSOList = () => http.get('/idp/');

export const deleteSSO = (id) => http.get(`/idp/delete/${id}`);

export const parseSSOMetadata = (payload) => http.post('/idp/parse-metadata', payload);

export const createSSO = (payload) => http.post('/idp/create', payload);

export const fetchSSOItem = (id) => http.get(`/idp/${id}`);

export const updateSSOItem = (id, payload) => http.post(`/idp/update/${id}`, payload);

// Return files page

export const fetchReturnPageMetadata = (id) =>
  http.get(`/files-return/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const fetchReturnUploadLink = (id, payload) =>
  http.post(`/files-return/upload-link/${id}`, payload, { validateStatus: (status) => status === 200 });

export const setReturnSent = (payload) =>
  http.post('/files-return/send', payload, { validateStatus: (status) => status === 201 });

export const makeFolderAtReturn = (id, payload) =>
  http.post(`/files-return/makedir/${id}`, payload, { validateStatus: (status) => status === 201 });

export const returnFilesLookup = (id, token) =>
  http
    .get(`/files-return/lookup/${id}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
      validateStatus: (status) => status === 200,
    })
    .then(lookupInterceptor);

// Tracking

export const fetchTracking = (id) => http.get(`/tracking/${id}`);

export const fetchTrackingFiles = (id) => http.get(`/tracking/files/${id}`);

export const fetchTrackingDownload = (id) => http.get(`/tracking/downloads/${id}`);

export const revokeQuickLink = (id) => http.get(`/quicklink/revoke/${id}`, { validateStatus: (status) => status === 200 });

export const revokeShare = (id) => http.get(`/share/revoke/${id}`, { validateStatus: (status) => status === 200 });

// Upload

export const finalizeChunkedUpload = (id) =>
  http.get(`/upload/finalize/${id}`, { validateStatus: (status) => status === 200 });

export const fetchUploadLink = (name, parentId, size) =>
  http.post(
    '/upload/link',
    {
      parent_id: parentId,
      name,
      upload_type: 'chunked',
      file_size: size,
    },
    { validateStatus: (status) => status === 200 }
  );

// API KEYS

export const fetchApiKeyList = () => http.get('/api-key/');

export const deleteApiKey = (id) => http.get(`/api-key/delete/${id}`);

export const createApiKey = (payload) => http.post('/api-key/create/', payload);

export const fetchApiKey = (id) => http.get(`/api-key/metadata/${id}`);

export const updateApiKey = (id, payload) => http.post(`/api-key/update/${id}`, payload);

// Search

export const fileSearch = (query, config) => http.post('/file/search/', query, config);

// Files

export const fileDelete = (payload) => http.post('/file/delete/', payload);

export const makeFolder = (payload) => http.post('/file/makedir/', payload, { validateStatus: (status) => status === 201 });

export const emptyTrash = (id, params) =>
  http.post(`/file/empty-trash/${id}`, {}, { params, validateStatus: (status) => [200, 202].includes(status) });

// API returns metadata with content by default.
export const getFileMetadata = (id, withContent = true) =>
  http.get(`/file/metadata/${id}${withContent ? '' : '?content=0'}`, { validateStatus: (status) => status === 200 });

export const getCommentsInFolder = (folderId) =>
  http.get(`/file/comment/parent/${folderId}`, { validateStatus: (status) => status === 200 });

export const getFileComments = (fileId) =>
  http.get(`/file/comment/${fileId}`, { validateStatus: (status) => status === 200 });

export const setComment = (fileId, data) =>
  http.post(`/file/comment/${fileId}`, data, { validateStatus: (status) => status === 200 });

export const getBreadcrumbs = (fileId) =>
  http.get(`/file/breadcrumbs/${fileId}`, { validateStatus: (status) => status === 200 });

// Comments

export const editComment = (commentId, data) =>
  http.post(`comment/edit/${commentId}`, data, { validateStatus: (status) => status === 200 });

export const deleteComment = (commentId) =>
  http.post(`comment/delete/${commentId}`, {}, { validateStatus: (status) => status === 200 });

// Billing

export const fetchPurchaseMetadata = () => http.get('/purchase/metadata/');

export const getPackagesForUpgrade = () => http.get('/package/');

export const countPrice = (data) => http.post('/purchase/calc-price/', data);

// Cards
export const getCards = () => http.get('/credit-card/', { validateStatus: (status) => status === 200 });

// Billing
export const getAutoRenewal = () => http.get('/purchase/auto-renewal/', { validateStatus: (status) => status === 200 });

export const setAutoRenewal = (data) =>
  http.post('/purchase/auto-renewal/', data, { validateStatus: (status) => status === 200 });

// MSFT
export const getWopiToken = (id, params) =>
  http.get(`/file/wopi-token/${id}`, { validateStatus: (status) => status === 200, params });

// File tags
export const getTagsForFile = (id) =>
  http
    .get(`/file/tags/${id}`, { validateStatus: (status) => status === 200 })
    .then(({ data }) => (data || []).filter((tag) => tag.type === 'text'));

export const getTagsForPath = (id) =>
  http.post(
    `/file/tags/parent/${id}`,
    {
      tag_types: ['text'],
    },
    { validateStatus: (status) => status === 200 }
  );

export const getTagsForAutocomplete = () => http.get('/tags/list', { validateStatus: (status) => status === 200 });

export const deleteTagFromFile = (id) => http.post(`/tags/delete/${id}`, { validateStatus: (status) => status === 200 });

export const addTagToFile = (id, value) =>
  http.post(
    `/file/add-tag/${id}`,
    {
      type: 'text',
      value,
    },
    { validateStatus: (status) => status === 200 }
  );
// Project-folders

export const getProjectFolderList = () => http.get('/project-folder', { validateStatus: (status) => status === 200 });

export const getProjectFolderMetadata = (id) =>
  http.get(`/project-folder/metadata/${id}`, { validateStatus: (status) => status === 200 });

// Service settings

export const getAccountLogoUploadLink = () =>
  http.get('/settings/upload-logo-link', { validateStatus: (status) => [200, 201].includes(status) });

export const uploadAccountImage = (id, data) =>
  http({
    baseURL: '',
    url: `/upload/image/${id}`,
    method: 'post',
    data,
    validateStatus: (status) => status === 200,
  });

// Invoices

export const getInvoices = () => http.get('/invoice', { validateStatus: (status) => status === 200 });

// SubFolder permissions

export const getSubFolderPermissions = (id) =>
  http.get(`/file/permissions/${id}`, { validateStatus: (status) => status === 200 });

export const setSubFolderPermissions = (id, payload) =>
  http.post(`/file/permissions/${id}`, payload, { validateStatus: (status) => status === 200 });

// PGP

export const requestPgpKey = (ids) =>
  http.post('/pgp-key/request', { ids }, { validateStatus: (status) => status === 202 });

// Automations

export const getAutomationList = (automationKey) =>
  http.get(`/automation${automationKey ? '?actions=' + automationKey : ''}`, {
    validateStatus: (status) => status === 200,
  });

export const getAutomationMetadata = (id) =>
  http.get(`/automation/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const createAutomation = (data) =>
  http.post('/automation/create', data, { validateStatus: (status) => status === 201 });

export const updateAutomation = (data) =>
  http.post('/automation/edit', data, { validateStatus: (status) => status === 200 });

export const deleteAutomation = (data) =>
  http.post('/automation/delete', data, { validateStatus: (status) => status === 200 });

export const getAutomationSchedules = (id) =>
  http.get(`/automation/schedule/${id}`, { validateStatus: (status) => status === 200 });

export const addAutomationSchedule = (id, payload) =>
  http.post(`/automation/schedule/${id}`, payload, { validateStatus: (status) => status === 200 });

export const runAutomationOnce = (id) =>
  http.post(`/automation/run/${id}`, {}, { validateStatus: (status) => status === 202 });

export const getAutomationRemotes = () => http.get('/automation/remote', { validateStatus: (status) => status === 200 });

export const getRemoteFolders = (id, payload) =>
  http.post(`/automation/remote/folders/${id}`, payload, { validateStatus: (status) => status === 200 });

export const getAutomationRunHistory = (payload) =>
  http.post('/automation/run-history', payload, { validateStatus: (status) => status === 200 });

export const getAutomationRunMetadata = (id) =>
  http.get(`/automation/run-history/metadata/${id}`, { validateStatus: (status) => status === 200 });

// Scheduler

export const updateSchedule = (id, payload) =>
  http.post(`/schedule/edit/${id}`, payload, { validateStatus: (status) => status === 200 });

export const deleteSchedule = (id) =>
  http.post(`/schedule/delete/${id}`, {}, { validateStatus: (status) => status === 200 });

export const getScheduleOverview = () => http.get('/schedule/overview', { validateStatus: (status) => status === 200 });

// Reset password page

export const passwordResetMetadata = (id) =>
  http.get(`/reset-password/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const saveResetPassword = (id, payload) =>
  http.post(`/reset-password/reset/${id}`, payload, { validateStatus: (status) => status === 200 });

export const requestPasswordReset = (data) =>
  http.post('/reset-password/request', data, { validateStatus: (status) => [200, 202].includes(status) });

// Share

export const getRecipientsKeys = (ids) =>
  http.post('/pgp-key/recipients', { ids }, { validateStatus: (status) => status === 200 });

// Remote sites

export const getRemoteSiteList = () => http.get('/remote-site', { validateStatus: (status) => status === 200 });

export const getRemoteSite = (id, params = {}) =>
  http.get(`/remote-site/metadata/${id}`, { params, validateStatus: (status) => status === 200 });

export const createRemoteSite = (data) =>
  http.post('/remote-site/create', data, { validateStatus: (status) => status === 201 });

export const updateRemoteSite = (id, data) =>
  http.post(`/remote-site/edit/${id}`, data, { validateStatus: (status) => status === 200 });

export const deleteRemoteSites = (ids) =>
  http.post('/remote-site/delete', { ids }, { validateStatus: (status) => status === 200 });

export const runTestConnection = (id) =>
  http.post(`/remote-site/test-connection/${id}`, {}, { validateStatus: (status) => status === 202 });

export const testRemoteConnectionByPayload = (payload) =>
  http.post('/remote-site/test-connection', payload, { validateStatus: (status) => status === 202 });

export const oauthStartAuthorization = (payload) =>
  http.post('/remote-site/oauth-start', payload, { validateStatus: (status) => status === 200 });

export const getDataTypeConfig = (id, payload) =>
  http.post(`/remote-site/configure/${id}`, payload, { validateStatus: (status) => status === 200 });

export const getRemotePermissions = (id) =>
  http.get(`/remote-site/permissions/${id}`, { validateStatus: (status) => status === 200 });

export const updateRemotePermissions = (id, payload) =>
  http.post(`/remote-site/edit-permissions/${id}`, payload, { validateStatus: (status) => status === 200 });

// Remote agents

export const getAgentsList = () => http.get('/agent', { validateStatus: (status) => status === 200 });

export const getAgent = (id) => http.get(`/agent/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const approveAgent = (id, data) =>
  http.post(`/agent/approve/${id}`, data, { validateStatus: (status) => status === 200 });

export const rejectAgent = (id) => http.post(`/agent/reject/${id}`, {}, { validateStatus: (status) => status === 200 });

export const updateAgent = (id, data) =>
  http.post(`agent/edit/${id}`, data, { validateStatus: (status) => status === 200 });

export const deleteAgent = (id) => http.post(`agent/delete/${id}`, {}, { validateStatus: (status) => status === 200 });

// Account

export const getAccountInfo = () => http.get('/account/info', { validateStatus: (status) => status === 200 });

export const getAccountRoles = () => http.get('/account/roles', { validateStatus: (status) => status === 200 });

// Contact

export const convertContact = (contactId, payload) =>
  http.post(`/contact/upgrade/${contactId}`, payload, { validateStatus: (status) => status === 200 });

export const getContactGroups = () => http.get('/contact/group', { validateStatus: (status) => status === 200 });

export const createContact = (payload) =>
  http.post('/contact/create', payload, { validateStatus: (status) => status === 201 });

// User

export const getUsers = () => http.get('/user', { validateStatus: (status) => status === 200 });

export const getUserGroups = () => http.get('/user/group', { validateStatus: (status) => status === 200 });

// Group

export const getGroups = () => http.get('/group', { validateStatus: (status) => status === 200 });

export const getGroup = (id) => http.get(`/group/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const createGroup = (payload) => http.post('/group/create', payload, { validateStatus: (status) => status === 201 });

export const updateGroup = (id, payload) =>
  http.post(`/group/edit/${id}`, payload, { validateStatus: (status) => status === 200 });

export const deleteGroup = (id, payload) =>
  http.post(`/group/delete/${id}`, payload, { validateStatus: (status) => status === 200 });

export const setGroupMembers = (id, payload) =>
  http.post(`/group/members/${id}`, payload, { validateStatus: (status) => status === 200 });

export const setGroupLinks = (id, payload) =>
  http.post(`/group/link/${id}`, payload, { validateStatus: (status) => status === 200 });
