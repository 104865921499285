import { QUATRIX_PLANS } from '@/utils/cons.js';

const getInitialState = () => ({
  serviceList: [],
  serviceSettings: {
    id: null,
    title: '',
    bcc: [],
    billing_emails: [],
    auth_methods: [],
    language: null,
    pgp_enabled: false,
    email_footer: '',
    share_types: {},
    ip_whitelist: [],
    dedicated_ip: false,
  },
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parseService: (state) => (resp) => {
      const property = resp.properties || {};
      resp.name = QUATRIX_PLANS[resp.key];
      if (Boolean(property.pgp_enabled)) {
        property.pgp_enabled = property.pgp_enabled === 'Y';
      }
      if (Boolean(property.public_share)) {
        property.public_share = property.public_share === 'Y';
      }
      if (Boolean(property.tracked_share)) {
        property.tracked_share = property.tracked_share === 'Y';
      }
      if (Boolean(property.restricted_share)) {
        property.restricted_share = property.restricted_share === 'Y';
      }
      property.bcc = property.bcc || [];
      property.email_footer = property.email_footer || '';
      return resp;
    },
    parseSettings: (state) => (resp) => {
      resp.bcc = resp.bcc || [];
      resp.billing_emails = resp.billing_emails || [];
      resp.auth_methods = resp.auth_methods || [];
      return resp;
    },
    getAttributes: (state) => (settings) => {
      return {
        ...(settings.bcc && { bcc: settings.bcc }),
        ...(settings.billing_emails && { billing_emails: settings.billing_emails }),
        ...(settings.ip_whitelist && { ip_whitelist: settings.ip_whitelist }),
        ...(settings.auth_methods && { auth_methods: settings.auth_methods }),
        ...(typeof settings.email_footer !== 'undefined' && { email_footer: settings.email_footer }),
        ...(settings.language && { language: settings.language }),
        ...(typeof settings.pgp_enabled !== 'undefined' && { pgp_enabled: settings.pgp_enabled }),
        ...(typeof settings.virus_scan !== 'undefined' && { virus_scan: settings.virus_scan }),
        ...(settings.share_types && { share_types: settings.share_types }),
        ...(settings.title && { title: settings.title }),
      };
    },
    baseService: (state) => {
      return state.serviceList.find((service) => service.base === true);
    },
    sftpService: (state) => {
      return state.serviceList.find((service) => service.key === 'SFTP');
    },
  },
  mutations: {
    setServices(state, services) {
      state.serviceList = services;
    },
    setServiceSettings(
      state,
      {
        properties: {
          name: title,
          public_share,
          tracked_share,
          restricted_share,
          bcc,
          billing_emails,
          language,
          pgp_enabled,
          email_footer,
          dedicated_ip,
        },
        id,
        auth_methods,
        ip_whitelist,
      }
    ) {
      state.serviceSettings = {
        id,
        auth_methods,
        ip_whitelist,
        title,
        share_types: {
          public_share,
          tracked_share,
          restricted_share,
        },
        bcc,
        billing_emails,
        language,
        pgp_enabled,
        email_footer,
        dedicated_ip,
      };
    },
    editServiceSettings(state, settings) {
      Object.keys(state.serviceSettings).forEach((key) => {
        if (typeof settings[key] !== 'undefined') {
          state.serviceSettings[key] = settings[key];
        }
      });
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    getList({ getters, commit }, options = {}) {
      return new Promise((resolve, reject) => {
        API.getServices(({ status }, resp) => {
          if (status === 200) {
            const services = resp.map((service) => getters.parseService(service));
            commit('setServices', services);
            commit('setServiceSettings', getters.baseService);
            typeof options.success === 'function' && options.success();
            resolve();
          } else {
            typeof options.error === 'function' && options.error(status, resp);
            reject();
          }
        });
      });
    },
    editSettings({ getters, commit }, options = {}) {
      API.editSiteSettings(getters.getAttributes(options.data), ({ status }, resp) => {
        if (status === 200) {
          commit('editServiceSettings', getters.parseSettings(resp));
          commit('profile/setPGPState', resp.pgp_enabled, { root: true });
          commit('share/setActiveShareTypes', resp.share_types, { root: true });
          commit('setAccountData', { title: resp.title }, { root: true });
          typeof options.success === 'function' && options.success();
        } else {
          return typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
  },
};
