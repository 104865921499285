import Vue from 'vue';
import GetTextPlugin from 'vue-gettext';
import { DEFAULT_LANG, AVAILABLE_LANGS } from '@/utils/cons.js';

import translations from '@/lang/translations.json';

Vue.use(GetTextPlugin, {
  availableLanguages: AVAILABLE_LANGS,
  defaultLanguage: DEFAULT_LANG,
  translations,
  silent: true,
});
