<template>
  <b-button
    v-if="!invisible"
    :id="id + '-button'"
    v-b-tooltip="tooltip"
    :data-id="id"
    :variant="variants[state]"
    :type="type"
    :disabled="inProgress || disabled"
    :block="isBlock"
    :tag="tag"
    :tabindex="tabindex"
    :href="href"
    :size="size"
    :target="href && 'blank'"
    @click="onClick">
    <div
      v-if="inProgress"
      class="spinner"
      :title="title">
      <qx-spinner-inline />
    </div>
    <div :class="{ hidden: inProgress }">
      <span>{{ title }}</span>
      <slot name="button-content" />
    </div>
  </b-button>
</template>

<script>
import QxSpinnerInline from '@/elements/QxSpinnerInline.vue';

export default {
  name: 'QxButton',
  components: {
    QxSpinnerInline,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    action: {
      type: Function,
      default: null,
    },
    state: {
      type: String,
      required: true,
      validator: (value) => {
        return [
          'active',
          'active-inverse',
          'passive',
          'active-red',
          'active-blue',
          'blue-text',
          'blue-text-hover-fill',
          'helper',
          'additional',
          'border-blue',
          'danger',
          'link',
        ].includes(value);
      },
    },
    type: {
      type: String,
      default: 'button',
    },
    preventDefault: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invisible: {
      type: Boolean,
      default: false,
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'button',
      validator: (value) => {
        return ['button', 'span'].includes(value);
      },
    },
    tabindex: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
      validator: (value) => {
        return ['sm', 'lg', ''].includes(value);
      },
    },
    tooltipTitle: {
      type: String,
      default: null,
    },
    tooltipPosition: {
      type: String,
      default: 'right',
      validator(value) {
        return [
          'top',
          'bottom',
          'right',
          'left',
          'topleft',
          'topright',
          'bottomleft',
          'bottomright',
          'lefttop',
          'leftbottom',
          'righttop',
          'rightbottom',
        ].includes(value);
      },
    },
    tooltipIsHtml: {
      type: Boolean,
      default: false,
    },
    tooltipContainer: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      variants: {
        active: 'custom-orange',
        'active-inverse': 'custom-orange-inverse',
        passive: 'default',
        'active-red': 'custom-red',
        'active-blue': 'custom-blue',
        'blue-text': 'blue-text',
        'blue-text-hover-fill': 'blue-text-hover-fill',
        helper: 'helper',
        additional: 'custom-light-blue',
        'border-blue': 'border-info-blue',
        danger: 'danger',
        link: 'link',
      },
    };
  },
  computed: {
    tooltip() {
      if (this.tooltipTitle?.length === 0) {
        return null;
      }
      return {
        title: this.tooltipTitle,
        html: this.tooltipIsHtml,
        placement: this.tooltipPosition,
        container: this.tooltipContainer,
      };
    },
  },
  methods: {
    onClick(event) {
      if (this.preventDefault) {
        event.preventDefault();
      }
      this.action?.();
    },
  },
};
</script>

<style lang="scss">
.btn {
  font-size: 1em;
  position: relative;
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .hidden {
    visibility: hidden;
    opacity: 0;
  }
  &.btn-lg {
    padding: 13px 36px;
  }
  &.btn-link {
    outline: none;
    box-shadow: none !important;
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/css/_buttons.scss';
</style>
