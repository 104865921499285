import Vue from 'vue';
import { userPermissionsLabels, getGroupCode } from '@/elements/utils.js';
import {
  getUserGroups,
  getContactGroups,
  getGroups,
  getGroup,
  createGroup,
  updateGroup,
  deleteGroup,
  setGroupMembers,
  setGroupLinks,
} from '@/api';

const getInitialState = () => ({
  userGroups: [],
  contactGroups: [],
  groups: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parse: (state, getters, rootState, rootGetters) => (group) => {
      group.groupCode = getGroupCode(group);
      group.groupAllowAdmin = group.metadata?.allow_admins;
      group.isSystem = group.metadata?.is_system;
      group.name = getters.getGroupName(group.groupCode) || group.name;
      if (group.metadata) {
        group.visual_order = group.metadata.visual_order;
      }
      if (group.operations !== undefined) {
        group.permissions = rootGetters['operations/parse']('user', group.operations);
        group.permissionsFormatted = getters.getGroupPermissionsLabels(group.permissions);
      }
      return group;
    },
    getAttributes: (state, getters, rootState, rootGetters) => (group) => {
      return {
        ...(group.name && { name: group.name }),
        ...(group.permissions && { operations: rootGetters['operations/getAttributes']('user', group.permissions) }),
        ...(group.lock_in_shared_folders !== undefined && { lock_in_shared_folders: group.lock_in_shared_folders }),
        ...(group.show_pf_users !== undefined && { show_pf_users: group.show_pf_users }),
        ...(group.default !== undefined && { default: group.default }),
      };
    },
    proGroup: (state) => {
      return state.userGroups.find((group) => group.groupCode === 'pro') || {};
    },
    siteGroup: (state) => {
      return state.contactGroups.find((group) => group.groupCode === 'stc') || {};
    },
    getPredefinedModel: (state, getters, rootState, rootGetters) => {
      let myGroup = {
        id: null,
        name: Vue.prototype.$gettext('My contacts'),
        readonly: !rootGetters['profile/checkOperations']('manage_contact'),
        global: false,
        type: 'C',
        metadata: {
          translation_id: 'my',
        },
      };
      myGroup.groupCode = getGroupCode(myGroup);
      return myGroup;
    },
    getGroupName: (state) => (groupCode) => {
      switch (groupCode) {
        case 'stc':
          return Vue.prototype.$gettext('Site contacts');
        case 'ast':
          return Vue.prototype.$gettext('Associate');
        case 'jld':
          return Vue.prototype.$gettext('Jailed');
        case 'pro':
          return Vue.prototype.$gettext('Pro');
        case 'my':
          return Vue.prototype.$gettext('My contacts');
        default:
          return '';
      }
    },
    defaultGroup: (state, getters) => {
      return (
        (state.userGroups.length > 0 ? state.userGroups : state.groups).find((group) => group.default) || getters.proGroup
      );
    },
    getGroupPermissionsLabels: (state, getters) => (permissions) => {
      const labels = userPermissionsLabels();
      const active = Object.keys(permissions)
        .filter((key) => {
          return permissions[key];
        })
        .map((permission) => labels[permission])
        .filter(Boolean);
      return active.join(', ');
    },
    getGroupById: (state) => (id) => {
      return state.groups.find((group) => group.id === id);
    },
  },
  mutations: {
    setUserGroups(state, groups) {
      state.userGroups = groups;
    },
    setContactGroups(state, groups) {
      state.contactGroups = groups;
    },
    setGroups(state, groups) {
      state.groups = groups;
    },
    addGroup(state, group) {
      state.groups.push(group);
    },
    updateGroup(state, group) {
      const groupIndex = state.groups.findIndex((item) => item.id === group.id);
      if (groupIndex !== -1) {
        const { isActiveState, isCheckedState, isDisabledState } = state.groups[groupIndex];
        const updatedGroup = {
          ...state.groups[groupIndex],
          ...group,
          isCheckedState,
          isActiveState,
          isDisabledState,
        };
        Vue.set(state.groups, groupIndex, updatedGroup);
      }
    },
    deleteGroup(state, id) {
      const groupIndex = state.groups.findIndex((item) => item.id === id);
      state.groups.splice(groupIndex, 1);
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getUserGroups({ getters, commit }) {
      const { data } = await getUserGroups();
      const groups = data.map(getters.parse);
      commit('setUserGroups', groups);
    },
    async getContactGroups({ getters, commit }) {
      const { data } = await getContactGroups();
      const groups = data.map(getters.parse);
      groups.push(getters.getPredefinedModel);
      commit('setContactGroups', groups);
    },
    async getGroups({ getters, commit }) {
      const { data } = await getGroups();
      const groups = data.map(getters.parse);
      commit('setGroups', groups);
    },
    async getGroup({ getters, commit }, id) {
      const { data } = await getGroup(id);
      const group = getters.parse(data);
      commit('updateGroup', group);
      return group;
    },
    async addGroup({ getters, commit }, payload) {
      const group = getters.getAttributes(payload);
      const { data } = await createGroup(group);
      const parsedGroup = getters.parse(data);
      commit('addGroup', parsedGroup);
      return parsedGroup;
    },
    async editGroup({ getters, commit }, { id, payload }) {
      const group = getters.getAttributes(payload);
      const { data } = await updateGroup(id, group);
      const parsedGroup = getters.parse(data);
      commit('updateGroup', parsedGroup);
      return parsedGroup;
    },
    async removeGroup({ commit }, { id, payload = {} }) {
      const { data } = await deleteGroup(id, payload);
      commit('deleteGroup', data);
    },
    async setGroupMembers({ getters, commit }, { id, payload }) {
      const { data } = await setGroupMembers(id, payload);
      const parsedGroup = getters.parse(data);
      commit('updateGroup', parsedGroup);
      return parsedGroup;
    },
    async setGroupLinks({ getters, commit }, { id, payload }) {
      const { data } = await setGroupLinks(id, payload);
      const parsedGroup = getters.parse(data);
      commit('updateGroup', parsedGroup);
      return parsedGroup;
    },
  },
};
