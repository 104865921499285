import { hasActiveFilter, getFilterItems } from '@/elements/utils.js';

export default {
  namespaced: true,
  state: {},
  getters: {
    filteredList: (state, getters) => (filters, filterStates, collection) => {
      let filteredData = [];
      const applyMainFilter = () => {
        const isActiveMain = hasActiveFilter(filters.main, filterStates);
        if (filters.main && isActiveMain) {
          getFilterItems(filters.main).forEach((item) => {
            if (filterStates[item.id]) {
              filteredData = filteredData.concat(getters.getListByFilter(item.field, item.value, collection));
            }
          });
        } else {
          filteredData = filteredData.concat(collection);
        }
      };
      const applyIntersectionFilter = () => {
        if (filters.intersection) {
          getFilterItems(filters.intersection).forEach((item) => {
            const isVisible = typeof item.isVisible !== 'undefined' ? item.isVisible : true;
            if (filterStates[item.id] && isVisible) {
              filteredData = getters.getListByFilter(item.field, item.value, filteredData);
            }
          });
        }
      };
      const applyTextFilter = () => {
        if (filters.text && filterStates[filters.text.id]) {
          filteredData = getters.filterText(filterStates[filters.text.id], filters, filteredData);
        }
      };

      applyMainFilter();
      applyIntersectionFilter();
      applyTextFilter();
      return filteredData;
    },
    getListByFilter: (state) => (field, value, collection) => {
      return collection.filter((model) => {
        if (typeof field === 'string') {
          return model[field] === value;
        } else {
          return field.reduce((m, f) => m[f], model) === value;
        }
      });
    },
    filterText: (state) => (text, filters, collection) => {
      if (!text) {
        return;
      }
      const escapedText = text.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
      const regexp = new RegExp(escapedText, 'i');
      return collection.filter((model) => {
        return filters.text.fields.find((field) => {
          const attr = field.split('.');
          const res = attr[1] ? model[attr[0]]?.[attr[1]] : model[attr[0]];
          return regexp.test(res);
        });
      });
    },
  },
  mutations: {},
  actions: {},
};
