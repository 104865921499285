import Vue from 'vue';
import moment from 'moment';
import { MOMENT_FORMATS } from '@/utils/cons.js';
import { getGroupCode } from '@/elements/utils.js';
import { createContact } from '@/api';

const getInitialState = () => ({
  contactList: [],
  recipientList: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parse: (state, getters, rootState, rootGetters) => (contact) => {
      if (contact.created !== undefined) {
        contact.createdUnix = contact.created;
        contact.created = moment.unix(contact.created).format(MOMENT_FORMATS.DATE);
      }
      contact.groups = contact.groups || [];
      if (!contact.groups.length && contact.type === 'C') {
        contact.groups.push(rootGetters['groups/getPredefinedModel']);
      }
      if (contact.groups && contact.groups.length) {
        contact.groupsId = contact.groups[0].id;
        contact.groupCode = getGroupCode(contact.groups[0]);
        contact.groups.forEach((group) => {
          group.name = rootGetters['groups/getGroupName'](group.metadata?.translation_id) || group.name;
        });
      }
      contact.isActive = contact.status === 'A';
      // Checked statuses in table
      contact.isDisabledState = false;
      contact.isCheckedState = false;
      contact.isActiveState = false;
      return contact;
    },
    getAttributes: () => (attr) => {
      return {
        ...(attr.name && { name: attr.name }),
        ...(attr.email && { email: attr.email.toLowerCase() }),
        ...(attr.hasOwnProperty('notes') && { notes: attr.notes }),
        ...(typeof attr.groupsId !== 'undefined' && { groups: attr.groupsId ? [attr.groupsId] : [] }),
        ...{ personal: attr.personal || !attr.groupsId || false },
      };
    },
    getContactById: (state) => (id) => {
      return state.contactList.find((contact) => contact.id === id);
    },
    getTmpModel: (state) => {
      return {
        id: null,
        name: '',
        email: '',
        notes: '',
        has_key: false,
        isActive: true,
        groups: [],
        groupsId: null,
        groupCode: '',
        readonly: false,
        owner_id: null,
        type: 'C',
        // Checked statuses in table
        isDisabledState: false,
        isCheckedState: false,
        isActiveState: false,
      };
    },
    getRecipientByEmail: (state) => (email) => {
      return state.recipientList.find((recipient) => recipient.email?.toLowerCase() === email.toLowerCase());
    },
  },
  mutations: {
    setContactList(state, contacts) {
      state.contactList = contacts;
    },
    setRecipientList(state, recipients) {
      state.recipientList = recipients;
    },
    addContact(state, contact) {
      state.contactList.push(contact);
      state.recipientList.push(contact);
    },
    updateContact(state, contact) {
      let contactIndex = state.contactList.findIndex((item) => item.id === contact.id);
      const { isActiveState, isCheckedState, isDisabledState } = state.contactList[contactIndex];
      const updatedContact = { ...contact, isCheckedState, isActiveState, isDisabledState };
      Vue.set(state.contactList, contactIndex, updatedContact);
    },
    deleteContacts(state, contactIds) {
      state.contactList.forEach((contact, index) => {
        if (contactIds.includes(contact.id)) {
          state.contactList.splice(index, 1);
        }
      });
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    getContacts({ getters, commit }, options = {}) {
      API.getContacts(({ status }, resp) => {
        if (status === 200) {
          let contacts = (resp || []).map((contact) => getters['parse'](contact));
          commit('setContactList', contacts);
          options.success?.(contacts);
        } else {
          options.error?.(status, resp);
        }
        options.finally?.();
      });
    },
    getContact({ getters, commit }, options = {}) {
      API.getContactMetadata(options.id, ({ status }, resp) => {
        if (status === 200) {
          const contact = getters.parse(resp);
          commit('updateContact', contact);
          typeof options.success === 'function' && options.success(contact);
        } else {
          return typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    async addContactAsync({ getters, commit }, options = {}) {
      const resp = await createContact(getters.getAttributes(options));
      const contact = getters.parse(resp.data);
      commit('addContact', contact);
      return resp.data;
    },
    addContact({ getters, commit }, options = {}) {
      API.createContact(getters['getAttributes'](options.data), ({ status }, resp) => {
        if (status === 201) {
          const contact = getters['parse'](resp);
          commit('addContact', contact);
          typeof options.success === 'function' && options.success(contact);
        } else {
          return typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    editContact({ getters, commit }, options = {}) {
      API.updateContact(options.data.id, getters['getAttributes'](options.data), ({ status }, resp) => {
        if (status === 200) {
          const contact = getters['parse'](resp);
          commit('updateContact', contact);
          typeof options.success === 'function' && options.success(contact);
        } else {
          return typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    deleteContacts({ commit, getters }, options = {}) {
      API.deleteContacts({ ids: options.ids }, ({ status }, resp) => {
        if (status === 200) {
          const response = (resp || []).map((item) => getters.getContactById(item.id));
          commit(
            'deleteContacts',
            resp.map((item) => item.id)
          );
          typeof options.success === 'function' && options.success(response);
        } else {
          return typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },

    getRecipients({ getters, commit }, options = {}) {
      return new Promise((resolve, reject) => {
        API.getRecipients(({ status }, resp) => {
          if (status === 200) {
            let recipients = (resp || []).map((recipient) => getters.parse(recipient));
            commit('setRecipientList', recipients);
            options.success?.(recipients);
            resolve();
          } else {
            return typeof options.error === 'function' && options.error(status, resp);
          }
        });
      });
    },
  },
};
