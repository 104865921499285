import Vue from 'vue';
import moment from 'moment';
import { MOMENT_FORMATS } from '@/utils/cons.js';

const getInitialState = () => ({
  inboxList: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parseAction: (state, getters, rootState, rootGetters) => (action) => {
      const inbox = {},
        activateTime = moment(action.activates * 1000),
        expireTime = action.expires ? moment(action.expires * 1000) : null;

      inbox.event = 'inbox';
      inbox.action = action.file_request ? 'file-request' : 'share';

      inbox.activates = activateTime.format(MOMENT_FORMATS.DATE_TIME);
      inbox.expires = expireTime ? expireTime.format(MOMENT_FORMATS.DATE_TIME) : null;
      inbox.file_request = action.file_request;
      inbox.id = action.id;
      inbox.is_reply = action.is_reply;
      inbox.protected = action.protected;
      inbox.replied = action.replied;
      inbox.sender_name = action.sender_name;
      inbox.subject = action.subject;
      inbox.type = rootGetters.getShareType(action.type);
      // Checked statuses in table
      inbox.isActive = true;
      inbox.isDisabledState = false;
      inbox.isCheckedState = false;
      inbox.isActiveState = false;
      return inbox;
    },
  },
  mutations: {
    clearInboxList(state) {
      state.inboxList = [];
    },
    setInboxList(state, inbox) {
      inbox.forEach((action) => {
        // filter duplicates
        if (!state.inboxList.find((event) => event.id === action.id)) {
          state.inboxList.push(action);
        }
      });
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    getInbox({ getters, commit, dispatch }, options = {}) {
      API.getInbox(
        {
          limit: options.limit,
          ...(options.id && { id: options.id }),
        },
        ({ status }, resp) => {
          if (status === 200) {
            let inbox = (Array.isArray(resp) ? resp : []).map((action) => getters['parseAction'](action));
            commit('setInboxList', inbox);
            dispatch('profile/getProfile', {}, { root: true });
            options.success?.(resp);
          } else {
            options.error?.(status, resp);
          }
          options.finally?.();
        }
      );
    },
  },
};
