import Vue from 'vue';
import { sortItems } from '@/elements/utils.js';

const getInitialState = () => ({
  userKeys: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parseKey: (state) => (key) => {
      const expires = key.expires * 1000;
      return {
        id: key.id,
        caption: key.caption,
        fingerprint: key.fingerprint,
        expires,
        isActive: key.status === 'A' && (expires === 0 || (expires !== 0 && expires > new Date().getTime())),
        // Checked statuses in table
        isDisabledState: false,
        isCheckedState: false,
        isActiveState: false,
      };
    },
    getAttributes: (state) => (key) => {
      return {
        ...(key.caption && { caption: key.caption }),
        ...(key.key && { key: key.key }),
        ...((key.expires || key.expires === 0) && { expires: key.expires }),
        ...(key.user_id && { user_id: key.user_id }),
      };
    },
    getKeyById: (state) => (id) => {
      return state.userKeys.find((key) => key.id === id);
    },
  },
  mutations: {
    setUserKeys(state, keys) {
      state.userKeys = keys;
    },
    updateKeys(state, key) {
      let keyIndex = state.userKeys.findIndex((item) => item.id === key.id);
      const { isActiveState, isCheckedState, isDisabledState } = state.userKeys[keyIndex];
      const updatedKey = { ...key, isCheckedState, isActiveState, isDisabledState };
      Vue.set(state.userKeys, keyIndex, updatedKey);
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    getKeysByUser({ getters, commit }, options = {}) {
      API.getUserSshKeys(options.user_id, ({ status }, resp) => {
        if (status === 200) {
          let keys = resp.map((key) => getters['parseKey'](key));
          keys = sortItems(keys, ['caption']);
          commit('setUserKeys', keys);
          typeof options.success === 'function' && options.success();
        } else {
          typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    getKey({ getters, commit }, options = {}) {
      API.sshMetadata(options.id, ({ status }, resp) => {
        if (status === 200) {
          const newKey = getters['parseKey'](resp);
          commit('updateKeys', newKey);
          typeof options.success === 'function' && options.success();
        } else {
          typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    addKey({ getters }, options = {}) {
      API.sshCreate(getters['getAttributes'](options.data), ({ status }, resp) => {
        if (status === 201) {
          typeof options.success === 'function' && options.success();
        } else {
          return typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    editKey({ getters }, options = {}) {
      API.sshEdit(options.data.id, getters['getAttributes'](options.data), ({ status }, resp) => {
        if (status === 200) {
          typeof options.success === 'function' && options.success();
        } else {
          typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
    deleteKey(context, options = {}) {
      API.sshDelete(options.id, ({ status }, resp) => {
        if (status === 200) {
          typeof options.success === 'function' && options.success();
        } else {
          typeof options.error === 'function' && options.error(status, resp);
        }
      });
    },
  },
};
