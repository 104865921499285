import { fetchReturnPageMetadata } from '@/api';
import EventBus from '@/elements/eventBus.js';

const getInitialState = () => ({
  isPageAccessAllowed: null,
  metadata: {
    isReturnEncrypted: false,
    isPgpEnabled: false,
    message: null,
    sender: {
      name: null,
      email: null,
    },
    folderId: null,
    senderKey: null,
    shareType: null,
  },
});

export default {
  namespaced: true,
  state: {
    welcome: {
      sender_name: null,
      share_type: null,
      date: null,
    },
    ...getInitialState(),
  },
  mutations: {
    setMetadata(state, data) {
      Object.assign(state.metadata, data);
    },
    setIsPageAcessAllowed(state, value) {
      state.isPageAccessAllowed = value;
    },
    setWelcome(state, data) {
      state.welcome = data;
    },
    resetState(state) {
      if (state.metadata.shareType !== 'P') {
        Object.assign(state, getInitialState());
      }
    },
  },
  actions: {
    async getMetadata({ commit, dispatch }, id) {
      try {
        const {
          data: {
            return_pgp_encrypted: isReturnEncrypted,
            pgp_enabled: isPgpEnabled,
            message,
            sender_name: name,
            sender_email: email,
            folder_id: folderId,
            user_name: userName,
            user_email: userEmail,
            sender_key: senderKey,
            return_request_auth: isNotPublic,
            share_type: shareType,
            files_per_share: filesPerShare,
          },
        } = await fetchReturnPageMetadata(id);
        commit('setMetadata', {
          isReturnEncrypted,
          isPgpEnabled,
          message,
          sender: { name, email },
          folderId,
          senderKey,
        });
        commit('setIsPageAcessAllowed', true);
        commit('profile/setFilesPerShare', filesPerShare, { root: true });

        if (isNotPublic) {
          dispatch('profile/getProfile', {}, { root: true });
        } else {
          commit('setProfileName', userName, { root: true });
          commit('auth/setUserEmail', userEmail, { root: true });
          // Hide spinner on public return when after logout
          EventBus.$emit('afterChangeRoute');
        }
      } catch (error) {
        commit('setIsPageAcessAllowed', false);
        if (error.response?.data?.details) {
          commit('setWelcome', error.response.data.details);
        }
        return Promise.reject(error);
      }
    },
  },
};
