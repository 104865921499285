const getBytesAndSplit = (bytes) => {
  const sizeLen = 4;
  if (bytes.length < sizeLen + 1) {
    return false;
  }
  const sizeBytes = bytes.slice(0, sizeLen);
  const bytesAndTail = bytes.slice(sizeLen);
  const size =
    (sizeBytes.charCodeAt(0) << (8 * 3)) +
    (sizeBytes.charCodeAt(1) << (8 * 2)) +
    (sizeBytes.charCodeAt(2) << 8) +
    (sizeBytes.charCodeAt(3) << 0);
  if (bytesAndTail.length < size) {
    return false;
  }
  const integerBytes = bytesAndTail.slice(0, size);
  const tail = bytesAndTail.slice(size);
  return [integerBytes, tail];
};

const checkIntegers = (num, bytes) => {
  let resBytes = bytes;
  let i;
  let ref;
  let result;
  for (i = 0, ref = num; 0 <= ref ? i < ref : i > ref; 0 <= ref ? ++i : --i) {
    result = getBytesAndSplit(resBytes);
    if (result === false) {
      return false;
    }
    resBytes = result[1];
  }
  return resBytes.length === 0;
};

export default (rawKey) => {
  const keyTokens = rawKey.trim().split(' ');
  if (keyTokens.length < 2) {
    return false;
  }
  const humanType = keyTokens[0];
  const keyBase64 = keyTokens[1];
  if (humanType !== 'ssh-rsa' && humanType !== 'ssh-dss') {
    return false;
  }
  let keyBytes;
  try {
    keyBytes = window.atob(keyBase64);
  } catch {
    return false;
  }
  const typeSizeParse = getBytesAndSplit(keyBytes);
  if (!typeSizeParse) {
    return false;
  }
  const keyBody = typeSizeParse[1];
  switch (humanType) {
    case 'ssh-rsa':
      return checkIntegers(2, keyBody);
    case 'ssh-dss':
      return checkIntegers(4, keyBody);
    default:
      return false;
  }
};
