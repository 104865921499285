import { fetchKeyRequestMetadata } from '@/api';

const getInitialState = () => ({
  metadata: {
    userName: null,
    userEmail: null,
    isContact: null,
  },
  isPageAccessAllowed: null,
});

export default {
  namespaced: true,
  state: {
    welcome: {
      sender_name: null,
    },
    ...getInitialState(),
  },
  mutations: {
    setMetadata(state, metadata) {
      state.metadata = { ...metadata };
    },
    setWelcome(state, data) {
      state.welcome = data;
    },
    setIsPageAcessAllowed(state, value) {
      state.isPageAccessAllowed = value;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getMetadata({ commit, dispatch }, id) {
      try {
        const {
          data: { user_name: userName, user_email: userEmail, is_contact: isContact },
        } = await fetchKeyRequestMetadata(id);
        commit('setMetadata', { userName, userEmail, isContact });
        commit('setIsPageAcessAllowed', true);
        dispatch('profile/getProfile', {}, { root: true });
      } catch (error) {
        commit('setIsPageAcessAllowed', false);
        if (error.response?.data?.details) {
          commit('setWelcome', error.response.data.details);
        }
        return Promise.reject(error);
      }
    },
  },
};
