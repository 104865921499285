import Vue from 'vue';
import _escape from 'lodash/escape';

import router from '@/router.js';

import { getByExtension } from '@/utils/fileIcons.js';
import { filesize } from '@/elements/utils.js';
import { makeFolder, makeFolderAtReturn } from '@/api';

const READER_FOR_TEST = new FileReader();

export const getFileDir = (file) => file.webkitRelativePath?.split('/')[0];

export const getFilePath = (file) =>
  file.webkitRelativePath?.slice(0, file.webkitRelativePath.length - (file.name.length + 1));

export const checkFileOnError = (file) =>
  new Promise((resolve, reject) => {
    READER_FOR_TEST.readAsText(file);
    READER_FOR_TEST.onload = resolve;
    READER_FOR_TEST.onerror = (e) => reject(e.target?.error);
  });

export const getUploadError = (code) => {
  switch (code) {
    case 96:
      return Vue.prototype.$gettext('Folder cannot be created');
    case 97:
      return Vue.prototype.$gettext('Failed to get upload link');
    case 98:
      return Vue.prototype.$gettext('File name is too long for encryption');
    case 99:
      return Vue.prototype.$gettext('Upload failed. Failed to encrypt file');
    case 122:
      return Vue.prototype.$gettext('Upload failed. Quota exceeded.');
    case 8:
    case 1: // 1 for Safari
      return Vue.prototype.$gettext('Upload failed. The file could not be found.');
    case 4: // 4 for Safari
    case 0:
      return Vue.prototype.$gettext('Upload failed. The file could not be read.');
    default:
      return Vue.prototype.$gettext('Upload failed, connection error, please try again later.');
  }
};

export const logUploadError = (msg, upload) => {
  const { file, ...externalFields } = upload;
  Sentry.captureException(new Error(`Upload: ${msg}`), {
    extra: { file: externalFields },
  });
};

export const showErrorMessage = ({ errorKey, maxSize, maxLength, message, duplicates }) => {
  if (errorKey) {
    let text, type;
    switch (errorKey) {
      case 'qpSize':
        text = Vue.prototype.$gettextInterpolate(
          Vue.prototype.$gettext("You can't upload the file more than %{ size }."),
          {
            size: filesize(maxSize),
          }
        );
        type = 'warning';
        break;
      case 'qpLength':
        text = Vue.prototype.$gettextInterpolate(
          Vue.prototype.$ngettext(
            'You are trying to upload more than %{ count } file. To upload unlimited amount of files and folders you can upgrade to Quatrix Business.',
            'You are trying to upload more than %{ count } files. To upload unlimited amount of files and folders you can upgrade to Quatrix Business.',
            maxLength
          ),
          { count: maxLength }
        );
        type = 'warning';
        break;
      case 'duplicate':
        if (duplicates.length === 1) {
          text = Vue.prototype.$gettextInterpolate(
            Vue.prototype.$gettext('You have already added a file %{ nameTag } to the upload list.'),
            { nameTag: '<strong>' + _escape(duplicates[0]) + '</strong>' },
            true
          );
        } else {
          text = Vue.prototype.$gettext('You have already added these files to the upload list:');
        }
        type = 'warning';
        break;
      case 'duplicateFolder':
        text = Vue.prototype.$gettext('You have already added a folder with the same name to the upload list.');
        type = 'warning';
        break;
      default:
        break;
    }
    Vue.prototype.$notify({
      text,
      type,
      data: {
        list: duplicates,
        format: (listItem) => listItem,
      },
    });
    return;
  }
  if (message) {
    Vue.prototype.$notify({
      text: message,
      type: 'error',
    });
  }
};

export class UploadItem {
  constructor({ file, name, uploadPath, parent }) {
    this.name = name || file.name;
    this.uploadPath = uploadPath;
    this.parent = parent;
    this.state = 'UPLOAD_QUEUED';
    this.received = 0;
    this.icon = UploadItem.getExtension(this.name);
    this.cancelSource = null; // Axios cancel method
    this.errorCode = null;
    this.link = null;
    this.id = null; //Id of uploaded file(we need to scroll to it)
  }

  get isCanceled() {
    return this.state === 'UPLOAD_CANCELED';
  }

  get isFinished() {
    return this.state === 'UPLOAD_FINISHED';
  }

  get isFailed() {
    return this.state === 'UPLOAD_ERROR';
  }

  get uploadId() {
    return this.link.match('^/upload/.+/([a-f0-9-]+)$')[1];
  }

  static getExtension(name) {
    const extension = name.substr(name.lastIndexOf('.') + 1).toLowerCase();
    return getByExtension(extension).icon;
  }

  start() {
    this.state = 'UPLOAD_STARTED';
  }

  setCancelSource(source) {
    this.cancelSource = source;
  }

  setId(id) {
    this.id = id;
  }

  cancel() {
    this.cancelSource?.cancel();
    this.state = 'UPLOAD_CANCELED';
  }

  progress(received) {
    this.received += received;
  }

  finish() {
    this.received = this.size;
    this.state = 'UPLOAD_FINISHED';
  }

  setLink(uploadKey) {
    this.link = `/upload/chunked/${uploadKey}`;
  }

  setError(code) {
    this.state = 'UPLOAD_ERROR';
    this.errorCode = code;
  }
}

export class UploadCloud extends UploadItem {
  constructor(file) {
    super(file);
    this.size = file.size;
    this.fileType = file.type;
    this.id = file.id;
  }
}

export class UploadFile extends UploadItem {
  constructor(props) {
    super(props);
    this.file = props.file;
    this.size = props.file.size;
  }
}

export class UploadDirectory extends UploadItem {
  constructor(props) {
    super(props);
    this.files = []; // list of files in dir
    this.dirs = {}; // id's of created dirs to keep tree structure
    this.count = 0; // Count of files in dir, getter from files.length wont work cause we pop file
    this.size = 0; // total size of all files inside dir
    this.uploaded = 0; //Count of uploaded files
    this.file = null; // file which will be uploaded next
  }

  getNextFile() {
    return this.files.pop();
  }

  finish() {
    super.finish(); // to avoid duplicate of parent method
    this.id = this.dirs[this.name];
  }

  addFile(file) {
    this.files.push(file);
    this.count++;
    this.size += file.size;
  }

  fileUploaded() {
    this.uploaded++;
  }

  setFileForUpload(file) {
    this.file = file;
  }

  async makeDirs(path) {
    if (this.dirs[path]) {
      //We can already created that path
      return this.dirs[path];
    }
    // Split webkitRelativePath into separate folders
    const pathList = path.split('/');
    let n = 0;

    if (!this.parent && router.currentRoute.name !== 'files-return') {
      // On return files we don't have parent, cause we aren't on file system
      return false;
    }

    let parent = this.parent;

    while (n < pathList.length) {
      const dirName = pathList[n];

      n++;

      const cachedDir = this.dirs[pathList.slice(0, n).join('/')];
      if (cachedDir) {
        parent = cachedDir;
        continue;
      }

      try {
        const { data } = await UploadDirectory.makeDir(dirName, parent);
        parent = data?.id;
        this.dirs[pathList.slice(0, n).join('/')] = parent;
      } catch (error) {
        //Failed to create folder
        return false;
      }
    }

    return parent;
  }

  static makeDir(name, target) {
    if (router.currentRoute.name === 'files-return') {
      return makeFolderAtReturn(router.currentRoute.params.id, { name, parent_id: target });
    }
    return makeFolder({ name, target });
  }
}
