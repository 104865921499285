import support from '@/libs/support';
import { UploadDirectory, UploadFile, UploadCloud } from '@/utils/uploadHelpers.js';

const getInitialState = () => ({
  isActive: false,
  currentDir: '',
  currentPath: '/',
  currentUpload: null,
  items: [],
  encryptionKeys: [],
  isSupportedPGPUpload:
    support.xhrFileUpload &&
    support.xhrFormDataFileUpload &&
    support.cryptoRandom &&
    support.typedArrays &&
    support.webWorkers,
});

// This module should be a function, cause we use it twice
// if we use as object it will be the same object for upload and shareUpload
export default () => ({
  namespaced: true,
  state: getInitialState(),
  getters: {
    directories: (state) => state.items.filter((item) => item instanceof UploadDirectory),
    standAloneFiles: (state) => state.items.filter((item) => item instanceof UploadFile),
    cloudFiles: (state) => state.items.filter((item) => item instanceof UploadCloud),
    getActiveUploads: (state) => state.items.filter((item) => item.state === 'UPLOAD_STARTED'),
    getQueuedUploads: (state) => state.items.filter((item) => item.state === 'UPLOAD_QUEUED'),
    getFinishedUploads: (state) => state.items.filter((item) => item.isFinished),
  },
  mutations: {
    addUpload(state, upload) {
      state.items.push(upload);
    },
    addUploadsAsArrays(state, uploads) {
      state.items = state.items.concat(uploads);
    },
    removeAll(state) {
      // We need to abort upload/chunked request of active file
      state.currentUpload?.cancel();
      state.items = [];
    },
    cancelAll(state) {
      state.items.forEach((item) => {
        if (item.state === 'UPLOAD_STARTED' || item.state === 'UPLOAD_QUEUED') {
          item.cancel();
        }
      });
    },
    removeUpload(state, item) {
      state.items.splice(state.items.indexOf(item), 1);
    },
    changeUploadState(state, status) {
      state.isActive = status;
    },
    setCurrentUpload(state, payload) {
      state.currentUpload = payload;
    },
    setCurrentDir(state, dir) {
      state.currentDir = dir;
    },
    setCurrentPath(state, path) {
      state.currentPath = path;
    },
    changeUploadDir(state, dir) {
      state.items.forEach((item) => {
        if (item.state === 'UPLOAD_QUEUED') {
          item.parent = dir;
        }
      });
    },
    setEncryptionKeys(state, keys) {
      state.encryptionKeys = keys;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
});
