import Vue from 'vue';
import Vuex from 'vuex';
import createMutationsSharer from 'vuex-shared-mutations';
import router from '@/router.js';

// modules by alphabet
import account from './modules/account.js';
import activity from './modules/activity.js';
import auth from './modules/auth.js';
import automations from './modules/automations.js';
import bgJobs from './modules/bgJobs.js';
import billing from './modules/billing.js';
import cards from './modules/cards.js';
import contacts from './modules/contacts.js';
import files from './modules/files.js';
import filters from './modules/filters.js';
import groups from './modules/groups.js';
import inbox from './modules/inbox.js';
import invoice from './modules/invoice.js';
import languages from './modules/languages.js';
import operations from './modules/operations.js';
import pgpKeys from './modules/pgpKeys.js';
import profile from './modules/profile.js';
import projectFolders from './modules/projectFolders.js';
import remotes from './modules/remotes.js';
import services from './modules/services.js';
import share from './modules/share.js';
import sorting from './modules/sorting.js';
import sshKeys from './modules/ssh-keys.js';
import sso from './modules/sso.js';
import tracking from './modules/tracking.js';
import uploadModule from './modules/upload.js';
import users from './modules/users.js';

// additional pages
import downloadPage from './modules/downloadPage.js';
import keyRequestPage from './modules/keyRequestPage.js';
import msft from './modules/msft.js';
import passwordPage from './modules/passwordPage.js';
import returnPage from './modules/returnPage.js';

import '@/api/maytech-api.js';

import { logout, accountMetadata } from '@/api';
import EventBus from '@/elements/eventBus.js';

Vue.use(Vuex);

const MUTATIONS_TO_RESET = [
  'resetState',
  'account/resetState',
  'activity/resetState',
  'auth/resetState',
  'bgJobs/resetState',
  'billing/resetState',
  'cards/resetState',
  'contacts/resetState',
  'downloadPage/resetState',
  'files/resetState',
  'groups/resetState',
  'inbox/resetState',
  'invoice/resetState',
  'keyRequestPage/resetState',
  'msft/resetState',
  'passwordPage/resetState',
  'pgpKeys/resetState',
  'profile/resetState',
  'projectFolders/resetState',
  'remotes/resetState',
  'returnPage/resetState',
  'services/resetState',
  'share/resetState',
  'sorting/resetState',
  'upload/resetState',
  'shareUpload/resetState',
  'sshKeys/resetState',
  'users/resetState',
];

const getInitialState = () => ({
  profileName: '',
});

const state = {
  ...getInitialState(),
  language: null,
  accountData: {
    isDefaultLogo: true,
    status: 'A',
    title: '',
    idps: [],
    disclaimers: [],
  },
  errorPageKey: '',
  updateTime: new Date().getTime(),
  delayedCall: null,
  loggedAsContact: false,
  currentRulesKey: 'main',
  isStandAlonePage: null,
  delayedRoute: null,
  isMobile: false,
  loginPageStyle: 'default',
  metadataLoaded: false,
};

const getters = {
  isPageAccessAllowed: (state, getters, rootState) => {
    if (state.currentRulesKey === 'main') {
      return false;
    } else {
      return rootState[state.currentRulesKey].isPageAccessAllowed;
    }
  },
  getShareType: () => (type) => {
    //TODO: move this function to utils it is static, no need in getter
    const types = {
      P: Vue.prototype.$gettext('Public'),
      T: Vue.prototype.$gettext('Tracked'),
      C: Vue.prototype.$gettext('Restricted'),
    };
    return types[type];
  },
  isSuspended: (state) => state.accountData.status === 'S',
  getLogo: (state) => (forceDefault = false) => {
    return forceDefault || state.accountData.isDefaultLogo
      ? '/img/quatrix_business_logo.svg'
      : '/account/logo?_=' + state.updateTime;
  },
  getLoginLogo: (state) => {
    return state.accountData.isDefaultLogo
      ? '/img/quatrix_business_logo.svg'
      : '/account/logo?_=' + new Date().getTime();
  },
  isSpecialLogin: (state) => state.loginPageStyle === 'globalsectrans',
  specialTextLogo: (state) => (state.loginPageStyle === 'globalsectrans' ? 'Securing success' : ''),
  getLoginSpecialData: (state, getters) => {
    return {
      title: getters.isSpecialLogin ? state.accountData.title || '' : '',
      loginBtn: getters.isSpecialLogin ? Vue.prototype.$gettext('External Log In') : '',
    };
  },
  getModules: (state, getters, rootState, rootGetters) => {
    const modules = [];
    const checkOperations = rootGetters['profile/checkOperations'];
    if (state.profile.operationsCode) {
      if (checkOperations('manage_files')) {
        modules.push({
          id: 'files',
          title: Vue.prototype.$gettext('File Explorer'),
          description: Vue.prototype.$gettext('Store your files in the Quatrix cloud'),
          icon: 'qx-icon icon-files',
          block: 'user',
        });
      }

      if (checkOperations('manage_shared_folder')) {
        modules.push({
          id: 'share',
          title: Vue.prototype.$gettext('Share Files'),
          description: Vue.prototype.$gettext('Share files with colleagues or business partners'),
          icon: 'qx-icon icon-share',
          block: 'user',
        });
      }

      if (checkOperations('manage_shared_folder')) {
        modules.push({
          id: 'contacts',
          title: Vue.prototype.$gettext('Contacts'),
          description: Vue.prototype.$gettext('Manage your contacts'),
          icon: 'qx-icon icon-contacts',
          block: 'user',
        });
      }

      if (checkOperations('manage_shared_folder')) {
        modules.push({
          id: 'activity-log',
          title: Vue.prototype.$gettext('Activity Log'),
          description: Vue.prototype.$gettext('Track activity history, includes detailed exports'),
          icon: 'qx-icon icon-history',
          block: 'user',
        });
      }

      if (checkOperations('manage_automation')) {
        modules.push({
          id: 'automations',
          title: Vue.prototype.$gettext('Automations'),
          description: Vue.prototype.$gettext('Manage your automations'),
          children: [
            {
              id: 'file-operations',
              title: Vue.prototype.$gettext('Internal Workflows'),
            },
            {
              id: 'external',
              title: Vue.prototype.$gettext('External Workflows'),
            },
            {
              id: 'schedule',
              title: Vue.prototype.$gettext('Schedule Overview'),
            },
          ],
          icon: 'qx-icon icon-automation',
          block: 'admin',
        });
      }

      if (checkOperations('manage_user') || checkOperations('manage_account')) {
        modules.push({
          id: 'account-info',
          title: Vue.prototype.$gettext('Administration'),
          description: Vue.prototype.$gettext('Manage your account and users'),
          icon: 'qx-icon icon-company-setting',
          block: 'admin',
        });
      }

      if (checkOperations('manage_billing')) {
        modules.push({
          id: 'billing',
          title: Vue.prototype.$gettext('Billing'),
          description: Vue.prototype.$gettext('Track and pay your invoices directly from your account'),
          icon: 'qx-icon icon-billing',
          block: 'admin',
        });
      }
    } else if (state.auth.isLoggedIn) {
      modules.push({
        id: 'inbox',
        title: Vue.prototype.$gettext('Inbox'),
        description: Vue.prototype.$gettext('Incomming files and requests tracking'),
        icon: 'qx-icon icon-history',
        block: 'user',
      });
    }
    return modules;
  },
  currentLanguage: (state) => () => state.language,
  getRootFolders: (state, getters) => (translate = false) => {
    const currentLanguage = getters['currentLanguage']();

    const translation = (englishName) =>
      currentLanguage === 'en_GB' || !translate ? englishName : Vue.prototype.$gettext(englishName);

    const folder = (id, englishName) => ({
      translationId: id,
      englishName,
      translation: translation(englishName),
    });
    return [
      folder('Incoming', 'Incoming Shares'),
      folder('Outgoing', 'Outgoing Shares'),
      folder('User homes', 'User Homes'),
      folder('Shared Projects', 'Projects Shared With Me'),
      folder('Trash', 'TRASH'),
    ];
  },
  getFoldersTranslateOptions: (state, getters) => (translate = false) => {
    const currentLanguage = getters.currentLanguage();
    const rootFolders = getters.getRootFolders(translate);
    return { currentLanguage, rootFolders, translate };
  },
};

const mutations = {
  setIsMobile(state, value) {
    state.isMobile = value;
  },
  setIsStandAlonePage(state, value) {
    state.isStandAlonePage = value;
  },
  setRules(state, key) {
    state.currentRulesKey = key;
  },
  setAccountData(state, payload) {
    if (typeof payload.default_logo !== 'undefined') {
      state.accountData.isDefaultLogo = payload.default_logo;
    }
    if (payload.status) {
      state.accountData.status = payload.status;
    }
    if (payload.title) {
      state.accountData.title = payload.title;
    }
    if (payload.language) {
      state.language = payload.language;
    }
    if (payload.idps) {
      state.accountData.idps = payload.idps;
    }
    if (payload.disclaimers) {
      state.accountData.disclaimers = payload.disclaimers;
    }
    if (payload.login_page_style) {
      state.loginPageStyle = payload.login_page_style;
    }
    state.metadataLoaded = true;
  },
  setLanguage(state, language) {
    state.language = language;
  },
  setErrorPageKey(state, errorKey) {
    state.errorPageKey = errorKey;
  },
  setUpdateForLogo(state, time) {
    state.updateTime = time;
  },
  setProfileName(state, name) {
    state.profileName = name;
  },
  setDelayedCall(state, args) {
    if (args && args.url && args.url !== '/session/keepalive') {
      state.delayedCall = args;
    }
  },
  clearDelayedCall(state) {
    state.delayedCall = null;
  },
  setLoggedAsContact(state, isContact) {
    state.loggedAsContact = isContact;
  },
  resetDelayedRoute(state) {
    // Save router location before logout, we ll get use back to this location after login
    state.delayedRoute = null;
  },
  pushRouterToMain(state) {
    // If we on main(home) or inbox(contact), we don't need to save that state, cause he will be redirected after login to the same location
    if (!['main'].includes(router.currentRoute.name) && !state.isStandAlonePage && !state.errorPageKey) {
      state.delayedRoute = router.currentRoute.fullPath;
      router.push({ name: 'main' }).catch(() => {});
    }
    //Reset cachedChildRoute values
    router.options.routes.forEach((route) => {
      if (route.meta?.cachedChildRoute) {
        route.meta.cachedChildRoute = null;
      }
    });
  },
  resetState(state) {
    Object.assign(state, getInitialState());
  },
};

const actions = {
  async getAccountMetadata({ commit }) {
    try {
      const { data } = await accountMetadata();
      commit('setAccountData', data);
      return data;
    } catch (error) {
      if (error.response?.status !== undefined) {
        switch (error.response.status) {
          case 404:
            commit('setErrorPageKey', 'errorNotFound');
            break;
          case 502:
            commit('setErrorPageKey', 'errorBadGateway');
            break;
          default:
            commit('setErrorPageKey', 'errorNotFoundDomain');
            break;
        }
      }
    }
  },
  async logout({ commit, dispatch }, source = 'user') {
    try {
      const {
        data: { session_status: sessionStatus },
      } = await logout({ source });
      // If FE keep alive timer run out, but something continued session(other tab send calls, etc)
      if (sessionStatus == 'active' && source === 'timeout') {
        return false;
      } else {
        // We show spinner to avoid blicks of home page
        EventBus.$emit('beforeChangeRoute');
        commit('pushRouterToMain');
        commit('auth/setIsLoggedIn', false);
        // call metadata to get correct sso list
        dispatch('getAccountMetadata');
      }
      return true;
    } catch (error) {
      return false;
    }
  },
  resetStore({ commit }) {
    // Reset state of all described modules
    MUTATIONS_TO_RESET.forEach((mutation) => {
      commit(mutation);
    });
  },
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    account,
    activity,
    auth,
    automations,
    bgJobs,
    billing,
    cards,
    contacts,
    files,
    filters,
    groups,
    inbox,
    invoice,
    languages,
    operations,
    pgpKeys,
    profile,
    projectFolders,
    remotes,
    services,
    share,
    shareUpload: uploadModule(),
    sorting,
    sshKeys,
    sso,
    tracking,
    upload: uploadModule(),
    users,
    downloadPage,
    keyRequestPage,
    msft,
    passwordPage,
    returnPage,
  },
  plugins: [
    createMutationsSharer({
      predicate: [
        'auth/setIsLoggedIn',
        'auth/setMfaCode',
        'auth/setUserEmail',
        'profile/setProfile',
        'pushRouterToMain',
        ...MUTATIONS_TO_RESET,
      ],
    }),
  ],
});
