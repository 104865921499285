import Vue from 'vue';
import { filesize, convertUnits, getGroupCode } from '@/elements/utils.js';
import { getUsers } from '@/api';
import debounce from 'lodash/debounce';

const getInitialState = () => ({
  usersList: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parse: (state, getters, rootState, rootGetters) => (resp) => {
      if (resp.status !== undefined) {
        resp.isActive = resp.status === 'A';
      }

      if (resp.email === null) {
        resp.email = '';
      }

      if (resp.effective_operations === null) {
        resp.effective_operations = resp.user_operations;
      }

      if (resp.user_operations !== undefined) {
        resp.user_operations = rootGetters['operations/parse']('user', resp.user_operations);
      }

      if (resp.effective_operations !== undefined) {
        resp.effective_operations = rootGetters['operations/parse']('user', resp.effective_operations);
        resp.roles = resp.roles || { user: true };
        resp.roles.administrator = rootGetters['operations/check']({
          operations: resp.effective_operations,
          permissions: ['administrator'],
        });
        resp.roles.billing = rootGetters['operations/check']({
          operations: resp.effective_operations,
          permissions: ['billing'],
        });
      }

      if (resp.home_operations !== undefined) {
        resp.home_operations = rootGetters['operations/parse']('file', resp.home_operations);
      }

      if (resp.quota !== undefined) {
        if (resp.quota === -1) {
          resp.quota_unit = 'U';
          resp.quota = '';
        } else {
          const quota = filesize(resp.quota).split(' ');
          resp.quota_unit = ['MB', 'GB'].includes(quota[1]) ? quota[1] : 'MB';
          resp.quota = convertUnits(quota[0], quota[1], resp.quota_unit);
        }
      }
      if (resp.reserved_storage !== undefined) {
        if (resp.reserved_storage <= 0) {
          resp.reserved_storage_unit = 'MB';
          resp.reserved_storage = '0';
        } else {
          const reserved_storage = filesize(resp.reserved_storage).split(' ');
          resp.reserved_storage_unit = ['MB', 'GB'].includes(reserved_storage[1]) ? reserved_storage[1] : 'MB';
          resp.reserved_storage = convertUnits(reserved_storage[0], reserved_storage[1], resp.reserved_storage_unit);
        }
      }
      resp.groups = resp.groups || [];
      resp.groupsId = resp.groups[0].id;
      resp.groupCode = getGroupCode(resp.groups[0]);
      resp.groups.forEach((group) => {
        group.name = rootGetters['groups/getGroupName'](group.metadata?.translation_id) || group.name;
      });

      if (resp.services && resp.services.length) {
        resp.isSftp = Boolean(resp.services.find((service) => service.service_key === 'SFTP'));
      }

      resp.isSet2fa = resp.auth_methods?.includes('2FA');

      // Checked statuses in table
      resp.isDisabledState = false;
      resp.isCheckedState = false;
      resp.isActiveState = false;
      return resp;
    },
    getAttributes: (state, getters, rootState, rootGetters) => (attr) => {
      const user = attr.ids && attr.ids.length ? getters.getUserById(attr.ids[0]) : {}; // check on mass edit action
      const attributes = {};

      if (attr.ids) {
        attributes.ids = attr.ids;
      }
      if (attr.name) {
        attributes.name = attr.name;
      }
      if (attr.hasOwnProperty('notes')) {
        attributes.notes = attr.notes;
      }
      if (attr.isActive !== undefined) {
        attributes.status = attr.isActive ? 'A' : 'D';
      }
      if (attr.email) {
        attributes.email = attr.email.toLowerCase();
      }
      if (attr.roles !== undefined) {
        const roles = Object.keys(attr.roles).filter((el) => attr.roles[el]);
        attributes.user_operations = rootGetters['operations/getAttributes'](
          'user',
          rootGetters['operations/getOperationsByGroups']('user', roles)
        );
      }
      if (attr.home_operations) {
        if (typeof attr.home_operations === 'object') {
          attributes.home_operations = rootGetters['operations/getAttributes']('file', attr.home_operations);
        } else {
          attributes.home_operations = attr.home_operations;
        }
      }
      if (attr.quota || attr.quota === 0 || attr.quota_unit) {
        attributes.quota =
          (attr.quota_unit || user.quota_unit) === 'U'
            ? -1
            : convertUnits(
                attr.quota || attr.quota === 0 ? attr.quota : user.quota,
                attr.quota_unit || user.quota_unit,
                'B'
              );
      }
      if (attr.reserved_storage || attr.reserved_storage === 0 || attr.reserved_storage_unit) {
        attributes.reserved_storage = convertUnits(
          attr.reserved_storage || attr.reserved_storage === 0 ? attr.reserved_storage : user.reserved_storage,
          attr.reserved_storage_unit || user.reserved_storage_unit,
          'B'
        );
      }
      if (attr.language) {
        attributes.language = attr.language;
      }
      if (attr.groupsId) {
        attributes.groups = [attr.groupsId];
      }
      if (attr.isSftp !== undefined) {
        attributes.services = attr.isSftp ? attr.sftpId : [];
      }
      if (typeof attr.sftp_login === 'string') {
        attributes.sftp_login = attr.sftp_login;
      }
      if (attr.sso_login_only !== undefined) {
        attributes.sso_login_only = attr.sso_login_only;
      }
      if (attr.use_expires !== undefined) {
        attributes.use_expires = attr.use_expires;
      }
      if (attr.expires !== undefined) {
        attributes.expires = attr.expires / 1000;
      }
      return attributes;
    },
    getUserById: (state) => (id) => {
      return state.usersList.find((user) => user.id === id);
    },
    getSuperAdmin: (state) => {
      return state.usersList.find((user) => user.super_admin === 'Y');
    },
    getTmpModel: (state, getters, rootState, rootGetters) => {
      return {
        ids: null,
        name: '',
        quota: '',
        quota_unit: 'U',
        reserved_storage: '',
        reserved_storage_unit: 'MB',
        isActive: true,
        email: '',
        user_operations: rootGetters['operations/parse']('user', 1),
        home_operations: rootGetters['operations/parse']('file', 4031),
        effective_operations: rootGetters['operations/parse']('user', 1),
        language: rootState.language,
        has_key: false,
        groups: [],
        groupsId: '',
        groupCode: '',
        services: [],
        roles: {
          administrator: false,
          user: true,
          billing: false,
        },
        isSftp: false,
        sftpId: '',
        pin: '',
        isPin: false,
        sftp_login: '',
        notes: '',
        sso_login_only: false,
        use_expires: false,
        expires: null,
        // Checked statuses in table
        isDisabledState: false,
        isCheckedState: false,
        isActiveState: false,
      };
    },
  },
  mutations: {
    setUsers(state, users) {
      state.usersList = users;
    },
    addUser(state, user) {
      state.usersList.push(user);
    },
    updateUsers(state, users) {
      users.forEach((user) => {
        let userIndex = state.usersList.findIndex((item) => item.id === user.id);
        if (userIndex !== -1) {
          const { isActiveState, isCheckedState, isDisabledState } = state.usersList[userIndex];
          const updatedUser = {
            ...state.usersList[userIndex],
            ...user,
            isCheckedState,
            isActiveState,
            isDisabledState,
          };
          Vue.set(state.usersList, userIndex, updatedUser);
        }
      });
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    // @TODO Do not use this action. Instead use getUsersList
    getUsers: debounce(
      ({ getters, commit }, options = {}) => {
        API.getUsers(({ status }, resp) => {
          if (status === 200) {
            let users = resp.map((user) => getters['parse'](user));
            commit('setUsers', users);
            options.success?.();
          } else {
            return options.error?.(status, resp);
          }
        });
      },
      300,
      {
        leading: true,
        trailing: false,
      }
    ),
    async getUsersList({ getters, commit }) {
      const { data } = await getUsers();
      if (data?.length > 0) {
        const users = data.map((user) => getters['parse'](user));
        commit('setUsers', users);
      }
      return data;
    },
    getUser({ getters, commit }, options = {}) {
      API.userMetadata(options.id, ({ status }, resp) => {
        if (status === 200) {
          const user = getters['parse'](resp);
          commit('updateUsers', [user]);
          options.success?.(user);
        } else {
          return options.error?.(status, resp);
        }
      });
    },
    addUser({ getters, commit }, options = {}) {
      API.userCreate(getters['getAttributes'](options.data), ({ status }, resp) => {
        if (status === 201) {
          const user = getters['parse'](resp);
          commit('addUser', user);
          options.success?.(user);
        } else {
          return options.error?.(status, resp);
        }
      });
    },
    editUsers({ getters, commit }, options = {}) {
      API.userEdit(getters['getAttributes'](options.data), ({ status }, resp) => {
        if (status === 200) {
          const users = resp.map((user) => getters['parse'](user));
          commit('updateUsers', users);
          options.success?.(users);
        } else {
          return options.error?.(status, resp);
        }
      });
    },
    deleteUsers(context, options = {}) {
      API.userDelete(
        {
          ids: options.ids,
          delete_home: options.delete_home || false,
        },
        ({ status }, resp) => {
          if (status === 202) {
            options.success?.(resp);
          } else {
            return options.error?.(status, resp);
          }
        }
      );
    },
  },
};
